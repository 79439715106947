import { useEffect, useState } from 'react';
import { LocalLoading } from '../../../components/Loading';
import { useSWRFetcher } from '../../../hooks/useStandardFetch';
import parse, { domToReact } from 'html-react-parser';
import { TestDetail } from './test.details.component';
import { motion } from 'framer-motion';
import { ItemTestList } from './itemTestList.component';
export function TestTab({ club, role }) {
  const { UidClub, ClubName, SubscriptionStatus } = club;
  const { data, isLoading, error } = useSWRFetcher(
    `app/tests?uidClub=${UidClub}`
  );

  const [resource, setResourse] = useState(TheApp?.Res);
  const [tests, setTests] = useState([]);
  const [details, setDetails] = useState({
    show: false,
    test: null
  });
  useEffect(() => {
    //if the language has been changed, I update the resource
    setResourse(TheApp?.Res[TheApp.Locale]);
  }, [resource]);
  useEffect(() => {
    if (data) {
      setTests(data.TestsSessions.Items);
    }
  }, [data]);
  //console.log(tests);
  function handlerSetDetailTest(test) {
    setDetails((prev) => ({
      show: !prev.show,
      test: test,
      role
    }));
  }

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  return (
    <div className="section-tab">
       {isLoading && ( <LocalLoading />)}
        <div className="container xs-p-0">
          {SubscriptionStatus === 0 ? (
            <div className="row comunications-wrapper xs-p-0">
              <div className="col-lg-12 xs-p-0">
                <div className="wrapper-message to-appear alert-warning visible">
                  <p>
                    {parse(`${resource['Dashboard.MessageWaitingClubTest']}`)}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              {!isLoading && tests?.length < 1 ? (
                <div className="row comunications-wrapper xs-p-0">
                  <div className="col-lg-12 xs-p-0">
                    <div className="wrapper-message to-appear alert-warning visible">
                      {resource && (
                        <p>{parse(`${resource['Dashboard.NoTestMessage']}`)}</p>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <motion.div
                  variants={container}
                  initial="hidden"
                  animate="visible"
                  className="wrapper-tests xs-p-0">
                  {details.show ? (
                    <TestDetail
                      resetDetailTest={handlerSetDetailTest}
                      details={details}
                      role={role}
                    />
                  ) : (
                    <>
                    {/* {isLoading && ( <LocalLoading />)} */}
                      {tests?.map((test) => {
                        return (
                          <ItemTestList
                            key={test.Uid}
                            test={test}
                            handlerSetDetailTest={handlerSetDetailTest}
                            ClubName={ClubName}
                          />
                        );
                      })}
                    </>
                  )}
                </motion.div>
              )}
            </>
          )}
        </div>
    </div>
  );
}
