import { useContext, useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { useStandardCall } from '../../../hooks/useStandardCall';
import SingleInput from '../../../components/SingleInput';
import { LocalLoading } from '../../../components/Loading';
import { toast } from 'react-toastify';

export function UserSecurityTabComponent() {
  const stdCall = useStandardCall();
  const [resource, setResourse] = useState(TheApp.Res);
  const appContext = useContext(AppContext);
  const [statusForm, setStatusForm] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm();
  useEffect(() => {
    if (statusForm) {
      if (statusForm?.success) {
        const toasts = toast.success(statusForm?.message);
      }
      if (!statusForm?.success) {
        const toasts = toast.error(statusForm?.message);
      }
    }
  }, [statusForm]);
  const inputs = [
    {
      id: 'old_password',
      label: 'Vecchia password',
      type: 'password',
      validationSchema: {
        required: `Campo obbligatorio`
      },
      placeholder: 'Inserisci la vecchia password',
      layout: 'col-lg-12 col-md-6',
      icon: 'fas fa-lock',
      defaultValue: ''
    },
    {
      id: 'new_password',
      label: 'Nuova password',
      type: 'password',
      defaultValue: '',
      validationSchema: {
        required: `Campo obbligatorio`,
        minLength: {
          value: 8
        },
        pattern: {
          value:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!£$%&=<>|])([^\s]){8,20}$/,
          message:
            'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale'
        }
      },
      placeholder: 'Inserisci la nuova password',
      icon: 'fa fa-lock',
      layout: 'col-lg-12 col-md-6'
    },
    {
      id: 'ConfirmPassword',
      label: 'Conferma Password',
      type: 'password',
      toCompare: true,
      defaultValue: '',
      validationSchema: {
        required: `Campo obbligatorio`,
        validate: {
          matchPreviousValue: (value) => {
            const { new_password } = getValues();
            return new_password === value || 'Le password non coincidono';
          }
        }
      },
      placeholder: 'Conferma la tua password',
      icon: 'fa fa-lock',
      layout: 'col-lg-12 col-md-6'
    }
  ];
  function updatePassword(data) {
    const { old_password, new_password } = data;
    const rq = {
      old_password,
      new_password
    };
    appContext._setters._setLoading(true);
    let call = stdCall.post('auth/passwordchange', rq, (rs) => {
      if (rs.Success) {
        setStatusForm({
          status: true,
          message: 'Password aggiornata con successo',
          success: true
        });
        appContext._setters._setLoading(false);
        reset();
      } else {
        console.log('error', rs.Message);
        setStatusForm({
          status: true,
          message: rs.Message,
          success: false
        });
        appContext._setters._setLoading(false);
      }
    });
  }
  return (
    <div className="wrapper-form">
      <form onSubmit={handleSubmit(updatePassword)}>
        <div className="row">
          {inputs.map((input) => (
            <SingleInput
              key={input.id}
              errors={errors}
              register={register}
              setValue={setValue}
              {...input}
            />
          ))}
          <div className="col-lg-12 col-md-6">
            <input
              type="submit"
              value={`Cambia Password`}
              className="btn primary"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
