import React, { useContext, useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useStandardCall } from '../../../hooks/useStandardCall';
import { useSWRFetcher } from '../../../hooks/useStandardFetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export function AddSubscriptionClub({ myProfile }) {
  const stdCall = useStandardCall();
  const appContext = useContext(AppContext);
  const subscription = myProfile.Subscriptions;
  const [options, setOptions] = useState([]);
  const { data, error, isLoading } = useSWRFetcher(`app/club?format=lookup`);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      clubs: null
    }
  });
  const [addSubscription, setAddSubscription] = useState(true);
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [statusForm, setStatusForm] = useState(null);
  const { fields, append, remove } = useFieldArray({
    name: 'clubs',
    control,
    rules: { maxLength: 1 }
  });
  useEffect(() => {
    //return an array that contains only items form data.Items that are not in the subscription array
    const filtered = data
      ? data.Items.filter(
        (item) => !subscription.some((sub) => sub.UidClub === item.Value)
      )
      : [];
    setOptions(
      filtered.map((item) => ({ label: item.Label, value: item.Value }))
    );
  }, [data, subscription]);
  useEffect(() => {
    if (statusForm) {
      if (statusForm?.success) {
        console.log('success');
        const toasts = toast.success(statusForm?.message);
      }
      if (!statusForm?.success) {
        console.log('fail');
        const toasts = toast.error(statusForm?.message);
      }
    }
  }, [statusForm]);
  //submit new subscription
  function submitNewSub(data) {
    const rq = {
      UidClub: data.clubs[0].club.value,
      UidUser: myProfile.Uid,
      Role: data.clubs[0].coach ? 'COACH' : 'PLAYER'
    };
    appContext._setters._setLoading(true);
    let call = stdCall.post('app/club/subscription', rq, (rs) => {
      if (rs.Success) {
        const sub = appContext.userSession.userData.myProfile.Subscriptions;
        const msg = appContext.userSession.userData.myProfile.MessagesCount;
        const newProfile = {
          ...appContext.userSession.userData.myProfile,
          MessagesCount: msg,
          Subscriptions: [
            {
              UidClub: data.clubs[0].club.value,
              ClubName: data.clubs[0].club.label,
              Coach: null,
              Role: data.clubs[0].coach ? 'COACH' : 'PLAYER',
              SubscriptionStatus: 0,
              SubscriptionStatusLabel: 'NOT ACTIVE',
              DateOfSubscription: new Date().toLocaleDateString()
            },
            ...sub
          ]
        };
        appContext._setters._setProfile(newProfile);
        appContext._setters._setLoading(false);
        reset();
        setStatusForm({
          status: true,
          message: 'La tua richiesta è stata inviata al club.',
          success: true
        });
      } else {
        appContext._setters._setLoading(false);
        setStatusForm({
          status: true,
          message: `Si è verificato un errore, riprova più tardi.`,
          success: false
        });
      }
    });
  }
  const label = {
    COACH: 'Allenatore',
    PLAYER: 'Giocatore'
  }
  return (
    <>
      {/* {isLoading && <Loading />} */}
      {data && (
        <div className="wrapper-form">
          {/* {loading && <LocalLoading />} */}
          <div className="wrapper-active-subscription">
            <h3 className="txt-uppercase">Le tue iscrizioni</h3>
            <div className="wrapper-subscription">
              {subscription.map((sub) => {
                return (
                  <div
                    className={`single-subscription  ${sub.SubscriptionStatus === 0 ? 'not-active' : sub.SubscriptionStatus === 2 ? 'suspended' : 'active'
                      }`}
                    key={`sub-${sub.UidClub}`}
                    id={sub.UidClub}>
                    <div className="wrapper-subscription-info">
                      <div className="subscription-info">
                        <div className="label-role">
                          <i className="fa-solid fa-user"></i>
                          <strong>{label[sub.Role]}</strong>
                        </div>
                        <p>
                          Nome Club:
                          <strong> {sub.ClubName}</strong>
                        </p>
                        {sub.coach && (
                          <p>
                            Il tuo coach:
                            <strong> {sub.Coach}</strong>
                          </p>
                        )}
                        <p>
                          Data sottoscrizione:{' '}
                          <strong>{sub.DateOfSubscription}</strong>
                        </p>
                        {sub.SubscriptionStatus === 0 && (
                          <p className="txt-center subscription-status-alert">
                            <i className="fa fa-triangle-exclamation"></i>
                            <strong>
                              {' '}
                              Siamo in attesa che il club confermi la tua
                              iscrizione.
                            </strong>
                          </p>
                        )}
                        {sub.SubscriptionStatus === 2 && (
                          <p className="txt-center subscription-status-alert">
                            <i className="fa fa-triangle-exclamation"></i>
                            <strong>
                              {' '}
                              La tua sottoscrizione è scadata, contatta il club per rinnovarla.
                            </strong>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {options.length > 0 && (
            <form onSubmit={handleSubmit(submitNewSub)}>
              <div className="row add-subscription">
                <div className="col-lg-12">
                  <h3 className="txt-uppercase">Aggiungi un club</h3>
                  <p>
                    Seleziona i club a cui vuoi iscriverti. Una volta che il
                    club avrà approvato la tua richiesta potrai visualizzare i
                    dati relativi al club.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  {fields.map((item, index) => {
                    return (
                      <div
                        className="row"
                        key={item.id}>
                        <div className={`wrapper-input col-lg-10`}>
                          <div className="single-input">
                            <Controller
                              key={index}
                              name={`clubs.${index}.club`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  key={index}
                                  options={options}
                                  placeholder={'Seleziona un club'}
                                  value={'Seleziona un club'}
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      borderWidth: 2,
                                      padding: '0.5rem',
                                      textTransform: 'capitalize',
                                      minHeight: 'unset'
                                    })
                                  }}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className="single-input">
                            <Controller
                              key={index}
                              name={`clubs.${index}.coach`}
                              control={control}
                              defaultValue={null}
                              render={({ field }) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        defaultValue={''}
                                        checked={field.value ? true : false}
                                        {...field}
                                        sx={{
                                          '&.Mui-checked': {
                                            color: '#00DAFF'
                                          }
                                        }}
                                      />
                                    }
                                    label={'Sei un maestro?'}
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="single-input">
                            <div
                              className="btn remove btn-remove-club"
                              onClick={() => {
                                setAddSubscription(true);
                                setActiveSubmit(false);
                                remove(index);
                              }}
                              style={{ height: '54px' }}>
                              <i
                                className="fa-regular fa-trash-can"
                                style={{ color: '#FFF' }}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  {addSubscription && (
                    <div
                      type="button"
                      className="btn primary"
                      onClick={() => {
                        setActiveSubmit(true);
                        setAddSubscription(false);
                        append({}); // append an empty object
                      }}>
                      Aggiungi Club
                    </div>
                  )}
                  {activeSubmit && (
                    <input
                      value={'invia richiesta'}
                      type="submit"
                      className="btn primary btn-submit-request-profile-club"
                    />
                  )}
                </div>
              </div>
            </form>
          )}
          {/* <div
            className={`wrapper-message to-appear ${
              statusForm.status
                ? statusForm.success
                  ? 'alert-success'
                  : 'alert-danger'
                : ''
            } ${statusForm.status ? 'visible' : ''}`}>
            {statusForm.status && <p>{statusForm.message}</p>}
          </div> */}
        </div>
      )}
    </>
  );
}
