import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../hooks/auth/useAppContext";
import "./home.scss";
import logo from "../../assets/logo.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import parse from "html-react-parser";

import logoFooter from "../../assets/logo-footer.png";
import logoDev from "../../assets/3x1010.png";
import headerVideo from "../../assets/Hypertennis-trailer.mp4";
import videoComplete from "../../assets/Hypertennis-completo.mp4";
import DigitalCoach from "../../assets/digital-coach.jpg";

import { content, partners } from "../../data/home";
import Footer from "../../components/Footer";

export function Home() {
  const appContext = useContext(AppContext);
  const [showVideo, setShowVideo] = useState(false);
  const [currentLng, setCurrentLng] = useState(
    window.location.pathname.split("/")[1] || "it"
  );
  const navigate = useNavigate();
  function videoShowHandler() {
    setShowVideo(!showVideo);
    document.querySelector("body").classList.toggle("no-scroll");
  }
  function lngHandler(locale) {
    const lng = locale.substr(0, 2).toLowerCase();
    appContext._setters._setLocale(locale);
    localStorage.setItem("lng", lng);
    navigate(`/${lng}`);
    setCurrentLng(lng);
    window.location.reload();
  }

  return (
    <div className="section-home">
      <nav>
        <div className="logo-wrapper">
          <img src={logo} alt="Hyper Tennis" />
        </div>
        <div className="wrapper-switch-lng">
          <div className="switch-lng">
            {TheApp.Env.LocaleList.map((locale) => (
              <div
                key={locale}
                data-locale={locale}
                className={`lng-item ${
                  currentLng === locale.substr(0, 2).toLowerCase()
                    ? "active"
                    : ""
                }`}
                onClick={() => lngHandler(locale)}
              >
                {locale.substr(0, 2).toUpperCase()}
              </div>
            ))}
            {TheApp.CurrentContext.IsAuthenticated ? (
              <div className="lng-item login">
                <a href={`/${currentLng}/dashboard`}>dashboard</a>
              </div>
            ) : (
              <div className="lng-item login">
                {/* <a href={`${currentLng}/auth/login`}>login</a> */}
              </div>
            )}
          </div>
        </div>
      </nav>
      <header>
        <div className="wrapper-video">
          <video autoPlay={true} loop muted playsInline preload="none">
            <source
              preload="none"
              src={content[currentLng].headerVideo}
              type="video/mp4"
            />
          </video>
          <div className="btn-show-video" onClick={videoShowHandler}>
            {content[currentLng].buttonVideo}
          </div>
        </div>
      </header>
      <section className="da-chi-nasce">
        <div className="container">
          {/* <div className="image-wrapper">
          <img
            src={andrea}
            alt="Andrea Candusso"
            className="andrea-img"
          />
        </div> */}
          <div className="content-wrapper">
            <div className="wrapper-text">
              <h2>{content[currentLng].bornSection.title}</h2>
              {parse(content[currentLng].bornSection.text)}
            </div>
          </div>
        </div>
      </section>
      <section className="quote">
        <blockquote>{parse(content[currentLng].firstQuote)}</blockquote>
      </section>
      <section className="digital-coach">
        <div className="container">
          <div className="wrapper-text">
            <h2>digital coach</h2>
            {parse(content[currentLng].digitalCoach)}
          </div>
          <div className="wrapper-img">
            <img src={DigitalCoach} alt="Digital Coach" />
          </div>
        </div>
      </section>
      <section className="quote">
        <blockquote>{parse(content[currentLng].secondQuote)}</blockquote>
      </section>
      <section className="how-to">
        <div className="container">
          <h2>{content[currentLng].howitworks.title}</h2>
          <div className="wrapper-img">
            <picture>
              <source
                media="(max-width:670px)"
                srcSet={content[currentLng].howitworks.imgMobile}
              />
              <img src={content[currentLng].howitworks.img} alt="How to" />
            </picture>
          </div>
        </div>
      </section>
      <section className="partners">
        <div className="container">
          <h2>{content[currentLng].partnersTitle}</h2>
          <div className="wrapper-partners">
            {partners.map((partner) => (
              <div className="single-partner" key={partner.id}>
                <a href={partner.urlLink} target="_blank" rel="noreferrer">
                  <img src={partner.img} alt={partner.name} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer logoDev={logoDev} logoFooter={logoFooter} />
      {showVideo && (
        <div className="modal-video">
          <div className="btn-close" onClick={videoShowHandler}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="wrapper-video">
            <video
              autoPlay={true}
              loop
              controls
              src={content[currentLng].videoComplete}
            ></video>
          </div>
        </div>
      )}
    </div>
  );
}
