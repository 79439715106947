import { useContext, useEffect, useState } from 'react';
import { useSWRInfiniteFetcher } from '../../../hooks/useStandardFetch';
import { LocalLoading } from '../../../components/Loading';
import { useStandardCall } from '../../../hooks/useStandardCall';
import { AppContext } from '../../../hooks/auth/useAppContext';

import { motion } from 'framer-motion';
import parse, { domToReact } from 'html-react-parser';
import userAvatar from '../../../assets/user-ph.png';
const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};
export function ComunicationsTab({ club }) {
  const appContext = useContext(AppContext);
  const { UidClub, ClubName } = club;
  const $PAGE_SIZE = 5;
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data, size, setSize, isLoading } = useSWRInfiniteFetcher(
    `app/messages?UidClub=${UidClub}`,
    $PAGE_SIZE
  );
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();
  const [statusForm, setStatusForm] = useState({
    status: false,
    message: '',
    success: false
  });
  let stdCall = useStandardCall();

  // set messages as default on load
  useEffect(() => {
    if (data) {
      //flat pages data into one array
      const flatteredData = data.map((item) => item.Items).flat();
      setMessages(flatteredData);
      setIsLoadingMore(data[data.length - 1].HasNextPage);
      setSelectedMessage(flatteredData[0]);
    }
  }, [data]);
  //mark as read message
  function handleMarkAsRead(message) {
    appContext._setters._setLoading(true);
    const { Uid } = message;
    let call = stdCall.post(`app/messages/read/${Uid}`, null, (rs) => {
      if (rs.Success) {
        const updateClub = {
          ...club,
          MessagesCount: club.MessagesCount - 1
        };
        //return others clubs
        const otherClub =
          appContext.userSession.userData.myProfile.Subscriptions.filter(
            (clubs) => clubs.UidClub !== club.UidClub
          );
        const newProfile = {
          ...appContext.userSession.userData.myProfile,
          Subscriptions: [updateClub, ...otherClub]
        };
        appContext._setters._setProfile(newProfile);

        const newMessages = messages.map((message) => {
          if (message.Uid === Uid) {
            message.Read = true;
          }
          return message;
        });
        setMessages(newMessages);
        appContext._setters._setLoading(false);
      } else {
        appContext._setters._setLoading(false);
        setStatusForm({
          status: true,
          message: `Si è verificato un errore, riprova più tardi.`,
          success: false
        });
      }
    });
  }


  const parser = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }
      if(attribs.id === 'selected-message-content'){
        return (
            domToReact(children, parser)
        );
      }
    }
  };


  return (
    <div className="section-tab">
      {isLoading && <LocalLoading />}
      {data && (
        <div className="container">
          {messages.length > 0 ? (
            <div className="row comunications-wrapper">
              <motion.div
                variants={container}
                initial="hidden"
                animate="visible"
                className="col-lg-6 col-sm-4 xs-4 xs-p-0">
                {messages.map((message) => {
                  return (
                    <motion.div
                      variants={item}
                      className={`single-comunication ${
                        message.Uid === selectedMessage?.Uid ? 'selected' : ''
                      }`}
                      key={message.Uid}
                      onClick={() => {
                        setSelectedMessage(message);
                      }}>
                      <div className="wrapper-info">
                        <div className="wrapper-img">
                          <img
                            src={message?.SenderProfileImg ? `${TheApp.Env.BaseUrl}${message.SenderProfileImg}` : userAvatar}
                            alt=""
                          />
                        </div>
                        <div className="message-info">
                          <h4>{message.SenderDisplayName}</h4>
                          <h2>
                            {message.Subject}
                            <div className="date">{message.Date}</div>
                          </h2>
                          <h3>{ClubName}</h3>
                        </div>
                      </div>
                      <div className="badge">
                        {message.Read ? (
                          <i className="fa-regular fa-message"></i>
                        ) : (
                          <i className="fa-solid fa-message"></i>
                        )}
                      </div>
                    </motion.div>
                  );
                })}
                {isLoadingMore && (
                  <motion.div
                    variants={item}
                    className="btn primary"
                    onClick={() => setSize(size + 1)}>
                    Load more
                  </motion.div>
                )}
              </motion.div>

              <div className="col-lg-6 col-sm-8 xs-8 has-no-pr-xs">
                {selectedMessage && (
                  <motion.div
                    className="selected-message"
                    initial="hidden"
                    animate="visible"
                    variants={container}>
                    <div className="wrapper-info">
                      <div className="message-info">
                        {!selectedMessage.Read && (
                          <div
                            className="mark-as-read"
                            onClick={() => handleMarkAsRead(selectedMessage)}>
                            mark as read
                            <i className="fa-solid fa-check"></i>
                          </div>
                        )}
                        <h4>{selectedMessage.SenderDisplayName}</h4>
                        <h2>
                          {selectedMessage.Subject}
                          <div className="date">
                            <i className="fa-regular fa-calendar"></i>{' '}
                            {selectedMessage.Date}
                          </div>
                        </h2>
                        <h3>{ClubName}</h3>
                        <p id='selected-message-content'>
                          {parse(
                            selectedMessage?.Content,
                            parser
                          )}
                  </p>
                      </div>
                    </div>
                  </motion.div>
                )}
                <div
                  className={`wrapper-message to-appear ${
                    statusForm.status
                      ? statusForm.success
                        ? 'alert-success'
                        : 'alert-danger'
                      : ''
                  } ${statusForm.status ? 'visible' : ''}`}>
                  {statusForm.status && <p>{statusForm.message}</p>}
                </div>
              </div>
            </div>
          ) : (
            <div className="row comunications-wrapper">
              <div className="col-lg-12">
                <div className="wrapper-message to-appear alert-warning visible">
                  <p>Non ci sono messaggi da visualizzare.</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
