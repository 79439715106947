import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { LocalLoading } from "../../../components/Loading";
import { useSWRFetcher } from "../../../hooks/useStandardFetch";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { AppContext } from "../../../hooks/auth/useAppContext";
import faTrashSolid from '../../../assets/icon/trash-solid.svg';
import faStarRegular from "../../../assets/icon/star-regular.svg";
import faAngleDownSolidAcc from "../../../assets/icon/angle-down-solidAccordion.svg";


export function BundleHistoryList({ player }) {
  const [resource, setResourse] = useState(TheApp.Res);


  const [options, setOptions] = useState([]);

  const [params, setParams] = useState({
    Year: 'all',
  });

  const {
    data,
    isLoading,
    errror,
  } = useSWRFetcher(`app/club/testsubscriptionshistory/${player}`);

  const [filteredBundles, setFilteredBundles] = useState([]);


  function handleFilterBundles(data) {
    if (data && data.value) {
      setParams({ Year: data.value });
    }
    else {
      setParams({ Year: "all" });
    }
  }

  function filterBundlesByYear(item){
    let year = new Date(item?.ValidityStartDate).getFullYear();
    return year == params.Year;
  }

  useEffect(() => {
    if (params && data) {
      console.log(data);
      if(params.Year == "all"){
        setFilteredBundles(data.Items);
      }
      else {
        let temp = data?.Items?.filter(filterBundlesByYear);
        setFilteredBundles(temp);
      }
    }
  }, [params, data]);

  useEffect(() => {
    if (data && params.Year == 'all') {
      let temp = data?.Items;
      let optionsTemp = [];
      for (let i = 0; i < temp?.length; i++) {
        let startDateYear = new Date(temp[i].ValidityStartDate).getFullYear();
        optionsTemp.push({
          value: startDateYear,
          label: startDateYear
        });
      }
      setOptions(optionsTemp);
    }
  }, [data])

  useEffect(() => {
    setResourse(TheApp.Res[TheApp.Locale]);
  }, [resource]);


  return (
    <>
      {(data &&  data?.Items.length > 0 ) ? (

        <div className="container club-confirm-panel">
          {isLoading && <LocalLoading />}
          <div className="row">
            <div className="col-lg-4">
              <h2
                className="bundles-title"
                style={{
                  marginBottom: '.5rem',
                  display: 'block',
                  textTransform: 'uppercase',
                }}>
                Seleziona un anno
              </h2>
              <Select
                onChange={(item) => handleFilterBundles(item)}
                isClearable={true}
                placeholder={"Seleziona un anno"}
                options={options}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderWidth: 2,
                    padding: '0.5rem',
                    textTransform: 'uppercase',
                    borderColor: "#162CA1",
                    color: "#162CA1"
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 100,
                    color: "#162CA1",
                    textTransform: "uppercase",
                    fontWeight: 500
                  })
                }} />
            </div>
          </div>
          <div className='bundles-container' style={{ marginTop:"25px" }}>
                    <header className="bundles-header">
                      <h2 className="bundles-title">Bundle scaduti</h2>
                      <p className="bundle-desc">
                        Puoi consultare il dettaglio di ciascun bundle espandendolo.
                      </p>
                    </header>
                  {
                    filteredBundles && filteredBundles?.map((item) => (
                        <Accordion key={item?.Uid}>
                          <AccordionSummary sx={{
                            fontWeight:"bold",
                              '&.Mui-expanded': {
                                // backgroundColor:"#00daff !important",
                                // borderRadius:"7px"
                              }
                            }}>
                              <h3 className="bundle-title">{`${item?.TestBundle?.BundleName}`}</h3>
                            {
                              faAngleDownSolidAcc && (
                                <img src={faAngleDownSolidAcc} alt="" draggable="false" role="presentation" className="icon-accordion icon-accordion-open" />
                              )
                            }
                          </AccordionSummary>
                          <AccordionDetails style={{display:"flex"}}>
                            <div className='row'>
                              <div className="col-lg-12 p-xs-inline-0">
                                <hr className="bundle-sep mt-0 mb-3" />
                              </div>
                              {
                                item?.TestBundle?.Items?.map((test, i) => {
                                  return (
                                    <div key={`${test?.TestType}-${i}`} className='col-sm-6 mb-xs-4 p-xs-inline-0'>
                                      <div className="action-test-container">
                                        <p className="test-title">
                                          {
                                            faStarRegular && (
                                              <img className="icon-accordion icon-accordion-test" src={faStarRegular} alt="" role="presentation" draggable="false" />
                                            )
                                          }
                                          <b>
                                            ({test?.NumberOfTestPerPlayer}) {test?.TestType}
                                          </b>
                                        </p>
                                        {
                                          test?.Items?.length && (
                                            <ul>
                                              {
                                                test.Items.map((exercise, i) => (
                                                  <li key={`${exercise?.Exercise}-${i}`}>
                                                    {exercise?.NumberOfExercises} {resource[`Lookup.ExerciseType.${exercise?.Exercise}`]}
                                                  </li>
                                                ))
                                              }
                                            </ul>
                                          )
                                        }
                                      </div>
                                    </div>
                                  )})
                              }
                              <div className="col-lg-12 p-xs-inline-0">
                                <hr className="bundle-sep mt-3 mb-3" />
                              </div>
                              <footer className="bundle-footer col-lg-12 p-xs-inline-0">
                                <div className="row">
                                  <div className="col-sm-6 d-flex align-items-center mb-xs-4 p-xs-inline-0">
                                    <h3 className="bundle-title">Scaduto il: <strong>{`${new Date(item?.ValidityEndDate).toLocaleDateString("it-it")}`}</strong></h3>
                                  </div>
                                </div>
                              </footer>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    )
                  }
                  </div>
        </div>
      ) :
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wrapper-message to-appear alert-warning visible">
                <p>La ricerca non ha prodotto risultati</p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
