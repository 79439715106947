export function Loading() {
  return (
    <div className="loading">
      <div className="loading-spinner"></div>
    </div>
  );
}

export function LocalLoading() {
  return (
    <div className="loading local">
      <div className="loading-spinner"></div>
    </div>
  );
}
