import { useForm } from 'react-hook-form';
import SingleInput from '../../../components/SingleInput';
import { useStandardCall } from '../../../hooks/useStandardCall';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export function AnagraficaUser({ myProfile }) {
  const appContext = useContext(AppContext);
  const [statusForm, setStatusForm] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm();
  const stdCall = useStandardCall();

  const inputs = [
    {
      id: 'FirstName',
      label: 'Nome',
      type: 'text',
      defaultValue: myProfile.FirstName,
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      placeholder: 'Inserisci il tuo nome',
      icon: 'fa fa-user',
      layout: 'col-lg-6'
    },
    {
      id: 'LastName',
      label: 'Cognome',
      type: 'text',
      defaultValue: myProfile.LastName,
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      placeholder: 'Inserisci il tuo cognome',
      icon: 'fa fa-user',
      layout: 'col-lg-6'
    },
    {
      id: 'Birthday',
      label: 'Data di nascita',
      type: 'date',
      defaultValue: myProfile.Birthday,
      validationSchema: {
        required: 'Campo obbligatorio',
        pattern: {
          value: /^\d{4}-\d{2}-\d{2}$/,
          message: 'Formato data non valido dd/mm/yyyy'
        }
      },
      icon: 'fa fa-calendar',
      layout: 'col-lg-6'
    },
    {
      id: 'BirthPlace',
      label: 'Luogo di nascita',
      type: 'text',
      defaultValue: myProfile.BirthPlace,
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      placeholder: 'Inserisci il tuo luogo di nascita',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-6'
    },
    {
      id: 'Sex',
      label: 'Sesso',
      type: 'select',
      isMulti: false,
      defaultValue: {
        value: myProfile?.Sex,
        label: myProfile?.Sex === 'M' ? 'Maschio' : myProfile?.Sex === 'F' ? 'Femmina' : 'Prefeisco non dirlo'
      },
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      options: {
        Items: [
          { Value: '', Label: 'Preferisco non dirlo' },
          { Value: 'M', Label: 'Maschio' },
          { Value: 'F', Label: 'Femmina' }
        ]
      },
      layout: 'col-lg-6'
    },
    {
      id: 'AddressStreet',
      label: 'Indirizzo',
      type: 'text',
      defaultValue: myProfile.AddressStreet,
      placeholder: 'Indirizzo di residenza',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-12 col-md-6'
    },
    {
      id: 'AddressNumber',
      label: 'Numero civico',
      type: 'text',
      defaultValue: myProfile.AddressNumber,
      placeholder: 'Numero civico',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-4 col-md-6'
    },
    {
      id: 'AddressTown',
      label: 'Città',
      type: 'text',
      defaultValue: myProfile.AddressTown,
      placeholder: 'Città di residenza',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-4 col-md-6'
    },
    {
      id: 'AddressZip',
      label: 'CAP',
      type: 'text',
      defaultValue: myProfile.AddressZip,
      placeholder: 'CAP di residenza',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-4 col-md-6'
    },
    {
      id: 'FiscalNumber',
      label: 'Codice Fiscale',
      type: 'text',
      defaultValue: myProfile.FiscalNumber,
      validationSchema: {
        pattern: {
          //regex for italian fiscal code
          value: /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i,
          message: 'Codice fiscale non valido'
        }
      },
      tooltip: {
        message: "Questo campo è utilizzato per..."
      },
      placeholder: 'Inserisci il tuo codice fiscale',
      icon: 'fa fa-id-card',
      layout: 'col-lg-12 col-md-6'
    },
    {
      id: 'fitp',
      label: 'N. Tessera FITP',
      type: 'text',
      defaultValue: myProfile.FITP,
      validationSchema:{
        pattern: {
          value: /^[0-9]*$/i,
          message: "Numero tessera FITP non valido"
        }
      },
      placeholder: 'Inserisci il numero della tua tessera',
      icon: 'fa fa-id-card',
      layout: 'col-lg-6'
    },    {
      id: 'FITPExpireDate',
      label: 'Scadenza tessera FITP',
      type: 'date',
      defaultValue:  myProfile.FITPExpireDate,
      validationSchema: {
        pattern: {
          value: /^\d{4}-\d{2}-\d{2}$/,
          message: 'Formato data non valido dd/mm/yyyy'
        }
      },
      placeholder: 'Inserisci il numero della tua tessera',
      icon: 'fa fa-calendar',
      layout: 'col-lg-6'
    },
  ];

  useEffect(() => {
    if (statusForm) {
      if (statusForm?.success) {
        console.log('success');
        const toasts = toast.success(statusForm?.message);
      }
      if (!statusForm?.success) {
        console.log('fail');
        const toasts = toast.error(statusForm?.message);
      }
    }
  }, [statusForm]);
  async function updateProfile(data) {
    //console.log(data);
    const { Sex, ...restData } = data;
    const img = myProfile.ImageProfile;
    const rq = {
      myProfile: {
        ...restData,
        ImageProfile: img,
        Sex: Sex.value
      }
    };
    appContext._setters._setLoading(true);
    let call = stdCall.post('app/updateprofile', rq, (rs) => {
      if (rs.Success) {
        //update profile
        const sub = appContext.userSession.userData.myProfile.Subscriptions;
        const msg = appContext.userSession.userData.myProfile.MessagesCount;
        const newProfile = {
          ...appContext.userSession.userData.myProfile,
          ...rs.myProfile,
          MessagesCount: msg,
          Subscriptions: sub
        };
        appContext._setters._setProfile(newProfile);
        appContext._setters._setLoading(false);
        setStatusForm({
          status: true,
          success: true,
          message: 'Profilo aggiornato correttamente'
        });
      } else {
        appContext._setters._setLoading(false);
        setStatusForm({
          status: true,
          success: false,
          message: rs.Message
        });
      }
    });
  }
  return (
    <div className="wrapper-form">
      <form onSubmit={handleSubmit(updateProfile)}>
        <div className="row">
          {inputs.map((input, index) => {
            return (
              <SingleInput
                key={input.id}
                errors={errors}
                register={register}
                control={control}
                setValue={setValue}
                {...input}
              />
            );
          })}
        </div>
        <input
          value={'Salva'}
          type="submit"
          className="btn primary"
        />
      </form>
    </div>
  );
}
