import { useContext, useEffect, useState } from "react";
import { LocalLoading } from "../../../components/Loading";
import { useSWRFetcher, useSWRInfiniteFetcher } from "../../../hooks/useStandardFetch";
import court from '../../../assets/tennis_court.jpg';
import ground from '../../../assets/tennis-ground.jpg';
import { TestDetail } from './test.details.component';
import { motion } from 'framer-motion';
import { useSearchParams } from "react-router-dom";
import SingleInput from "../../../components/SingleInput";
import { useForm } from "react-hook-form";
import { useDebounce } from "../../../hooks/useDebounce";
import { AppContext } from "../../../hooks/auth/useAppContext";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export function CoachApprovalTest({ club }) {
  const appContext = useContext(AppContext);
  const [resource, setResourse] = useState(TheApp?.Res);
  const [searchParams, setSearchParams] = useSearchParams();
  const { UidClub } = club;
  const [PAGE_SIZE, SETPAGESIZE] = useState(5);
  const [test, setTest] = useState();
  const [params, setParams] = useState({Search:''})
  const { data, mutate, size, setSize, isValidating, isLoading } = useSWRInfiniteFetcher(`app/testsforapproval?uidClub=${UidClub}`, PAGE_SIZE);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [details, setDetails] = useState({
    show: false,
    test: null
  });
  const [query, setQuery] = useState(null);



  
  function handlerSetDetailTest(test) {

    setDetails((prev) => {
      // quando e nascosto cancello testapproval da query string
      setSearchParams(prev.show ? {} : { testapproval: test?.Uid });
      return ({
        show: !prev.show,
        test: test,
      })
    }
    );
  }

  const inputsFilter = [
    // {
    //   id: 'Search',
    //   label: resource['Form.Search'] ?? "Cerca",
    //   type: 'text',
    //   defaultValue: params?.Search || '',
    //   placeholder: resource['Form.SearchFirstNameLastNamePlaceholder'] ?? "Cerca per nome, cognome",
    //   icon: 'fa fa-search',
    //   layout: 'col-lg-4 col-md-4'
    // },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({});

  useEffect(() => {
    if (test && searchParams.get('testapproval')) {
      let testFound = test.find(x => x.Uid == searchParams.get('testapproval'));
      if (testFound) {
        setSearchParams({ testapproval: testFound?.Uid });
        setDetails({
          show: true,
          test: testFound
        });
      }
    }
  }, [test, searchParams])

  function handleFilterTest(data) {
    setQuery(data);
  }
  const searchQuery = useDebounce(query, 500);

  useEffect(() => {
      if (searchQuery && searchQuery.Search.length > 0) {
        appContext._setters._setLoading(true);
        const searchFiltered = data[data?.length - 1].TestsSessions?.Items.filter((test) => {
              let name = test?.PlayerFullName?.toLowerCase()?.split(" ");
              return name.some(x => x?.startsWith(searchQuery?.Search?.toLowerCase())) || test?.PlayerFullName?.toLowerCase().includes(searchQuery?.Search?.toLowerCase())
              });
        SETPAGESIZE(50);
        setTest(searchFiltered);
        setIsLoadingMore(false);
        appContext._setters._setLoading(false);
      }
      else{
        SETPAGESIZE(5);
      }
  }, [searchQuery]);


  useEffect(() => {
    if (data && params?.Search?.length === 0) {
      const flatteredData = data.map((item) => item.TestsSessions.Items).flat();
      setTest(() => flatteredData);
      setIsLoadingMore(data[data.length - 1].TestsSessions.HasNextPage);
    }
  }, [data]);

  return (
    <div className="section-tab">
      {isLoading || isValidating && <LocalLoading />}

      {details.show ? (
        <div className="wrapper-tests xs-p-0">
          <TestDetail
            resetDetailTest={handlerSetDetailTest}
            details={details}
            role="COACH"
          />
        </div>
      ) : (
        <>

          <div className="row">
            <div className="col-lg-12">
              <form
                className="form-filter"
                onChange={handleSubmit(handleFilterTest)}>
                <div className="container">
                  <div className="row align-items-center">
                    {inputsFilter.map((input) => {
                      return (
                        <SingleInput
                          key={input.id}
                          errors={errors}
                          register={register}
                          control={control}
                          setValue={setValue}
                          {...input}
                        />
                      );
                    })}
                  </div>
                </div>
              </form>
            </div>
          </div>

          {isValidating && <LocalLoading />}
          {data && test && test.length > 0 ? (
            <>
              <motion.div
                className="wrapper-tests"
                initial="hidden"
                animate="visible"
                variants={container}
              >
                {test.map((test) => (
                  <motion.div
                    className="single-test single-test-to-approve row xs-p-0"
                    key={test.Uid}
                    variants={item}
                  >
                    <div className="col-lg-5 xs-p-0">
                      <div
                        className="wrapper-img"
                        style={{ backgroundColor: "#162CA1" }}
                      >
                        <i className="fa-solid fa-spinner"></i>
                        <img src={court} alt="" />
                      </div>
                    </div>
                    {/* <label htmlFor="" className="progress">
                                        {new Date(test.Date).toLocaleDateString()}
                                    </label> */}
                    <div className="col-lg-7 xs-p-0">
                      <div className="wrapper-info">
                        <h2>
                          {test.TestName} {test.TestDurationMin} minuti
                          <span>
                            <span className="label">
                              <i className="fa-regular fa-calendar-check"></i> Data:{' '}
                            </span>
                            {new Date(test.Date).toLocaleDateString()}
                          </span>
                        </h2>
                        <ul className="personal-info">
                          <li className="coach">{test.PlayerFullName}</li>
                          <li className="height">{test.PlayerHeight}{test.PlayerHeightUnit}</li>
                          <li className="age">{test.PlayerAge}</li>
                          <li className="weight">{test.PlayerWeight}{test.PlayerWeightUnit}</li>
                          <li className="dominant-hand">{test.PlayerDominantHandLeft ? 'Mancino' : 'Destro'}</li>
                        </ul>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <article className="single-test-card card-exercise">
                            <h3><i className="fa-solid fa-list"></i> Esercizi</h3>
                            <ul className="exercise-list">
                              {test.Exercises.map((excersise) => (<li key={excersise.Uid}>
                                {excersise.NumberOfExercises} {excersise.ExerciseLabel}
                              </li>))}
                            </ul>
                          </article>
                        </div>
                        {
                          test.CoachNote && (
                            <div className="col-lg-12 card-notes-cont">
                              <article className="single-test-card card-notes">
                                <h3><i className="fa-regular fa-note-sticky"></i> Note Generali</h3>
                                <p>{test.CoachNote}</p>
                              </article>
                            </div>
                          )
                        }

                      </div>
                      <div
                        className="btn secondary"
                        onClick={() => {
                          handlerSetDetailTest(test);
                        }}>
                        visualizza test
                      </div>
                    </div>
                  </motion.div>

                ))}
              </motion.div>
              {isLoadingMore && (

                <div className="offset-lg-4 col-lg-4">
                  <motion.div
                    variants={item}
                    className="btn primary"
                    style={{ marginBottom: '2rem' }}
                    onClick={() => setSize(() => size + 1)}>
                    Load more
                  </motion.div>
                </div>
              )}
            </>) :
            !isLoading && (
              <div className="wrapper-message to-appear alert-warning visible"><p><strong>Attenzione!</strong> non ci sono test da approvare</p></div>
            )
          }
        </>)}
    </div>
  )
}
