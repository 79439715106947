export function NoMatch() {
  return (
    <div className="page-error">
      <div className="wrapper-message">
        <h1>404</h1>
        <h2>page not found</h2>
        <a
          href="/"
          className="btn primary">
          Torna alla home
        </a>
      </div>
    </div>
  );
}
