import { useContext, useEffect, useState } from 'react';
import { useSWRFetcher } from '../../../hooks/useStandardFetch';
import { Loading, LocalLoading } from '../../../components/Loading';
import { motion } from 'framer-motion';
import SingleInput from '../../../components/SingleInput';
import { useForm } from 'react-hook-form';
import { useDebounce } from '../../../hooks/useDebounce';
import { SenderMesssage } from './sender.message.components';
import userAvatar from '../../../assets/user-ph.png';
import { ClubApproveProfileUser } from './club.approveProfileUser.component';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { BundleHistoryList } from './BundleHistoryList.component';

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};


export function CoachesListTab({ user, club: { UidClub, Role, ClubName } }) {
  const appContext = useContext(AppContext);
  const { data, isLoading, error } = useSWRFetcher(
    `app/club/coaches/${UidClub}`
  );
  const [coaches, setCoaches] = useState(
    Role === 'TECH_DIRECTOR'
      ? data?.Items.filter((item) => item?.UidCoach !== user)
      : data?.Items
  );
  const [query, setQuery] = useState(null);
  const [formVisble, setFormVisible] = useState(false);
  const [clubApproveUser, setClubApproveUser] = useState({
    show: false,
    player: null
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({});
  //filter data.Items and exlude the coach that is logged in
  const imputsFilter = [
    {
      id: 'Search',
      label: 'Cerca',
      type: 'text',
      defaultValue: '',
      placeholder: 'Cerca per nome, cognome',
      icon: 'fa fa-search',
      layout: 'col-lg-4 col-md-4'
    },
    {
      id: 'Filters',
      type: 'radioGroup',
      layout: 'col-lg-5 col-md-6',
      defaultValue: 'fullname',
      inputs: [
        {
          id: 'fullname',
          label: 'Alfabetico Cognome',
          group: 'Filters'
        },
        // {
        //   id: 'lasttest',
        //   label: 'Ultimo Test',
        //   group: 'Filters'
        // },
        {
          id: 'subscription',
          label: 'Data Iscrizione',
          group: 'Filters'
        }
      ]
    }
  ];
  const inputsSender = [
    {
      id: 'Subject',
      label: 'Oggetto',
      type: 'text',
      placeholder: 'Oggetto messaggio',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-12 col-md-12'
    },
    {
      id: 'Content',
      label: 'Messaggio',
      type: 'textarea',
      placeholder: 'Insersci il messaggio da inviare',
      icon: 'fa fa-map-marker',
      layout: 'col-lg-12 col-md-12'
    }
  ];

  const [bundleHistory, setBundleHistory] = useState({
    show:false,
    player: null,
  })


  function handlerBundleHistory(){
    setBundleHistory({show:true, player: clubApproveUser.player});
    setClubApproveUser({ show: false, player:null });
  }

  useEffect(() => {
    if (data) {
      let sorted = data?.Items.sort((a, b) => {
        //sort by last name
        return a.LastName.toLowerCase() > b.LastName.toLowerCase() ? 1 : -1;
      });
      setCoaches(
        Role === 'TECH_DIRECTOR'
          ? data?.Items.filter((item) => item?.UidCoach !== user) : sorted
      );
    }
  }, [data, Role, user]);
  function handleFilterPlayer(data, e) {
    setQuery(data);
  }
  const searchQuery = useDebounce(query, 500);
  //in base alla query di ricerca, filtra i dati
  useEffect(() => {
    if (searchQuery) {
      appContext._setters._setLoading(true);
      const searchFiltered =
        searchQuery.Search !== ' '
          ? data?.Items.filter((coach) => {
            // esclude utente attuale dalla lista filtrata
            if(coach.UidCoach != user)
              return (
                coach?.LastName?.toLowerCase().includes(
                  searchQuery.Search.toLowerCase()
                ) ||
                coach?.FirstName?.toLowerCase().includes(
                  searchQuery.Search.toLowerCase()
                )
              );
            })
          : Role === 'TECH_DIRECTOR'
          ? data?.Items.filter((item) => item?.UidCoach !== user)
          : data?.Items;
      const sorted = searchFiltered.sort((a, b) => {
        if (searchQuery.Filters === 'fullname') {
          return a.LastName.toLowerCase() > b.LastName.toLowerCase() ? 1 : -1;
        }
        // Non esiste colonna LastTest per i coaches
        // if (searchQuery.Filters === 'lasttest') {
        //   //TODO da sistemare il sort per data ultimo test
        //   // /* const dateA = a.LastTest.split('/');
        //   // const dateB = b.LastTest.split('/');
        //   // return new Date(dateA[2], dateA[1], dateA[0]).getTime() <
        //   //   new Date(dateB[2], dateB[1], dateB[0]).getTime()
        //   //   ? 1
        //   //   : -1; */
        // }
        if (searchQuery.Filters === 'subscription') {
          const dateA = a.DateOfSubscription.split('/');
          const dateB = b.DateOfSubscription.split('/');
          return new Date(dateA[2], dateA[1], dateA[0]).getTime() <
            new Date(dateB[2], dateB[1], dateB[0]).getTime()
            ? 1
            : -1;
          }
        return null;
      });
      setCoaches(sorted);
      appContext._setters._setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, Role, data, user]);
  //mostro il form per inviare un messaggio
  function handlerShowForm(player) {
    setFormVisible((prev) => ({ show: !prev.show, player }));
  }

  function handlerApproveProfileClub(player) {
    setClubApproveUser((prev) => ({ show: !prev.show, player }));
  }
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  return (
    <div className="section-tab">
      <>
        {!formVisble.show && !clubApproveUser.show && !bundleHistory.show && (
          <div className="row">
            <div className="col-lg-12">
              <form
                className="form-filter"
                onChange={handleSubmit(handleFilterPlayer)}>
                <div className="container">
                  <div className="row align-items-center">
                    {imputsFilter.map((input) => {
                      return (
                        <SingleInput
                          key={input.id}
                          errors={errors}
                          register={register}
                          control={control}
                          setValue={setValue}
                          {...input}
                        />
                      );
                    })}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {isLoading && <Loading />}
          {data?.Items && (
            <>
              {formVisble.show ? (
                <SenderMesssage
                  inputs={inputsSender}
                  player={formVisble.player}
                  handlerShowForm={handlerShowForm}
                  UidClub={UidClub}
                />
              ) : (
                <>
                  {clubApproveUser.show ? (
                    <div style={{ padding: '2rem 1rem' }}>
                      <div
                        className="btn-close"
                        onClick={() => {
                          setClubApproveUser((prev) => ({
                            show: !prev.show,
                            player: null
                          }));
                        }}>
                        <i className="fa-solid fa-circle-xmark"></i>
                      </div>
                      <ClubApproveProfileUser
                        handlerBundleHistory={handlerBundleHistory}
                        role={'COACH'}
                        club={UidClub}
                        player={clubApproveUser.player}
                      />
                    </div>
                    
                  ) :
                    bundleHistory.show ? (
                      <div style={{ padding: '2rem 1rem' }}>
                      <div
                        className="btn-close"
                        onClick={() => {
                          setClubApproveUser((prev) => ({
                            show:true,
                            player: bundleHistory.player
                          }));
                          setBundleHistory((prev) => ({
                            show: !prev.show,
                            player:null,
                          }));
                        }}>
                        <i className="fa-solid fa-circle-xmark"></i>
                      </div>
                      <BundleHistoryList
                        player={bundleHistory.player}
                      />
                    </div>
                    )
                    :
                  (
                    <>
                      {coaches && coaches.length > 0 ? (
                        <>
                          {isLoading && <Loading />}
                          {
                            coaches && (
                              <div className="wrapper-players container">
                                {
                                  coaches.map((coach, i) => {
                                    return (
                                      <motion.div
                                      key={`${coach.UidCoach}`}
                                      variants={item}
                                      className="row single-player"
                                      >
                                      {/* <div className="row align-items-center"> */}
                                      <div
                                          className="col-4 col-lg-2 col-sm-4 sm-2 xs-4"
                                          style={{ position: 'relative' }}>
                                          {coach.SubscriptionStatus === 0 && (
                                            <div className="subscription-status-badge">
                                              Da approvare
                                            </div>
                                          )}
                                          <div className="wrapper-img">
                                            <img
                                              src={
                                                coach.ImageProfile
                                                  ? `${TheApp.Env.BaseUrl}${coach.ImageProfile}`
                                                  : `${userAvatar}`
                                              }
                                              alt={coach.FullName}
                                              className={
                                                coach.ImageProfile ? '' : 'no-img'
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-9 col-lg-5 col-sm-6 sm-3 xs-8">
                                          <div className="wrapper-info">
                                            <h3>{coach.FullName}</h3>
                                            <h4 className="subscription-date">
                                              Data iscrizione:{' '}
                                              <strong>
                                                {coach.DateOfSubscription}
                                              </strong>
                                            </h4>
                                          </div>
                                        </div>
                                        <div className="col-6 col-lg-3 col-sm-6 sm-3 xs-12 has-mt-xs-2">
                                          <div className="test-info">
                                            <h3 className="test-info-label">
                                              <span>Numero Allievi:</span>{' '}
                                              {coach.PlayerCount}
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="col-6 col-lg-2 col-sm-6 sm-4 xs-12 has-no-pr-xs has-mt-xs-2">
                                          <div className="player-action-btns">
                                            <div
                                              className="btn small secondary message"
                                              onClick={() => {
                                                handlerShowForm(coach.UidCoach);
                                              }}>
                                              Invia messaggio
                                            </div>
                                            {
                                              Role !== "TECH_DIRECTOR" && (
                                                <div
                                                  className="btn small secondary profile"
                                                  onClick={() => {
                                                    handlerApproveProfileClub(
                                                      coach.UidCoach
                                                    );
                                                  }}>
                                                  <span>Vedi dettaglio</span>
                                                </div>
                                              )
                                            }
                                          </div>
                                        </div>
                                    </motion.div>
                                    )
                                  })
                                }
                              </div>
                            )
                          }
                        </>
                      ) : (
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="wrapper-message to-appear alert-warning visible">
                                <p>La ricerca non ha prodotto risultati</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
      </>
    </div>
  );
}
