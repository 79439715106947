export default function Footer({ logoFooter, logoDev }) {
  return (
    <footer>
      <div className="container">
        <div className="footer-row">
          <div className="wrapper-logo">
            <img
              src={logoFooter}
              alt="Hyper Tennis"
            />
          </div>
        </div>
        <div className="footer-row">
          <div className="wrapper-info">
            <p>
              HyperTennis S.R.L.
              <br />
              Sede Legale: Via Vincenzo Vela, 42 - 10128 Torino (TO) - Partita
              IVA: 12521860010 - REA: TO-1296190 <br />
              Codice univoco: USAL8PV -{' '}
              <a href="mailto:hypertennis@pec.it">hypertennis@pec.it</a>
              <br />
            </p>
          </div>
          <div className="wrapper-dev">
            <div>Developed by</div>
            <div>
              <a
                href="https://www.3x1010.it"
                target="_blank"
                rel="noreferrer">
                <img
                  src={logoDev}
                  alt="Developed by 3x1010"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
