import { useContext, useEffect, useState } from 'react';
import { useSWRFetcher } from '../../../hooks/useStandardFetch';
import { Loading, LocalLoading } from '../../../components/Loading';
import SingleInput from '../../../components/SingleInput';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { useStandardCall } from '../../../hooks/useStandardCall';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Accordion, AccordionDetails, AccordionSummary, Typography, colors } from '@mui/material';
import Select from 'react-select';
import { BundleHistoryList } from './BundleHistoryList.component';
import faTrashSolid from '../../../assets/icon/trash-solid.svg';
import faStarRegular from "../../../assets/icon/star-regular.svg";
import faAngleDownSolidAcc from "../../../assets/icon/angle-down-solidAccordion.svg";


export const ClubApproveProfileUser = ({ handlerBundleHistory, club, player, role,  }) => {
  const appContext = useContext(AppContext);
  const Locale = appContext.locale;
  const [resource, setResourse] = useState(TheApp.Res);
  const stdCall = useStandardCall();
  const [statusForm, setStatusForm] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const PAGE_SIZE = 5;
  const urlToFetch =
    role === 'COACH'
      ? `app/club/coach/${club}/${player}`
      : `app/club/player/${club}/${player}`;
  const {
    data: user,
    isLoading: userLoading,
    error: errorUser,
    mutate
  } = useSWRFetcher(urlToFetch);

  const {
    data: coaches,
    isLoading: coachesLoading,
    errror: errorCoaches
  } = useSWRFetcher(`app/club/coaches/${club}`);

  const [selectedBundle, setSelectedBundle] = useState();

  const {
    data: bundles,
    isLoading: bundlesLoading,
    errror: bundlesError
  } = useSWRFetcher(`app/club/bundles`);

  const {
    data: testSubscriptions,
    isLoading: testSubscriptionsLoading,
    errror: testSubscriptionsError,
    mutate: testSubscriptionsMutate
  } = useSWRFetcher(`app/club/testsubscriptions/${player}`);

  const {
    register: registerBundle,
    handleSubmit: handleSubmitBundle,
    setValue :setValueBundle,
    control: controlBundle,
    formState: { errors: errorsBundle }
  } = useForm({});

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({});

  const [profile, setProfile] = useState(null);
  function changeModalVisibility(modalObj) {
    setModalData(modalObj);
    setModal(true);
  }

  useEffect(() => {
    if (user) {
      // Formats the date from yyyy-mm-dd to dd-mm-yyyy
      let dateStr = user?.Profile?.Birthday.split("-").reverse().join("/");
      setProfile({...user.Profile, Birthday: dateStr});
    }
  }, [user]);
  
  useEffect(() => {
    if (statusForm) {
      if (statusForm?.success) {
        const toasts = toast.success(statusForm?.message);
        mutate();
      }
      if (!statusForm?.success) {
        const toasts = toast.error(statusForm?.message);
      }
    }
  }, [statusForm, mutate]);

  const filteredCoaches = coaches?.Items?.filter(coach => coach.SubscriptionStatus === 1);
  const options = filteredCoaches.map((coach) => {
    return {
      Value: coach.UidCoach,
      Label: coach?.IsTechDirector ? coach?.FullName + "  (DIRETTORE TECNICO)" : coach.FullName
    };
  });

  const bundleOptions = bundles?.Items?.map((bundle) => {
    return {
      value: bundle?.UidBundle,
      label: bundle?.BundleName,
    }
  });

  function handleBundleChange(e) {
    setSelectedBundle(bundles?.Items?.find((item) => item.UidBundle == e?.value))
  };

  const inputsApprove = [
    {
      id: 'PlayerType',
      label: 'Seleziona la tipologia',
      placeholder: 'Seleziona la tipologia',
      type: 'radioGroup',
      inputs: [
        {
          id: 'socio',
          label: 'Socio',
        },
        {
          id: 'not_socio',
          label: 'Non socio',
        },
      ],
      defaultValue: profile?.Subscription?.UserType == 0 ? "not_socio" : "socio",
      layout: 'col-lg-12'
    },
    {
      id: 'UidCoach',
      label: 'Seleziona un allenatore',
      placeholder: 'Seleziona un allenatore',
      type: 'select',
      options: {
        Items: options
      },
      defaultValue: profile?.Subscription?.Coach
        ? {
          value: profile.Subscription.Coach.UidCoach,
          label: profile.Subscription.Coach.FullName
        }
        : null,
      layout: 'col-lg-12'
    },
  ];

  function handleDelSubscription(e) {
    e.preventDefault();
    const payload = {
      UidUser: player,
      UidClub: profile.Subscription.UidClub,
      Role: profile.Subscription.Role
    };
    appContext._setters._setLoading(true);
    const call = stdCall.del(`app/club/subscription`, payload, (rs) => {
      if (rs.Success) {
        setStatusForm({
          status: true,
          message: 'Utente rimosso con successo',
          success: true
        });
        appContext._setters._setLoading(false);
      } else {
        setStatusForm({
          status: true,
          message: rs.Message,
          success: false
        });
        appContext._setters._setLoading(false);
      }
    });
  }

  async function handleDisableUserBundle(e,item){
    const payload = {
      Uid: item?.Uid,
      UidUser: player,
      UidClub: profile.Subscription.UidClub
    };

    appContext._setters._setLoading(true);
    const call = await stdCall.del("app/club/testsubscription", payload, (rs) => {
      if(rs?.Success){
        setStatusForm({
          status: true,
          message: 'Bundle rimosso con successo',
          success: true
        });
        testSubscriptionsMutate({...testSubscriptions});
        appContext._setters._setLoading(false);
      }else{
        setStatusForm({
          status: true,
          message: rs.Message,
          success: false
        });
        appContext._setters._setLoading(false);
      }
    });
  }

  async function handleAddBundle() {
    if(bundleOptions?.length == 0) return;
    let Bundle = selectedBundle;

    const payload = {
      UidUser: player,
      UidClub: profile.Subscription.UidClub,
      UidBundle: Bundle?.UidBundle
    };
    appContext._setters._setLoading(true);
    const call = await stdCall.post(`app/club/bundles`, payload, (rs) => {
      if (rs.Success) {
        setStatusForm({
          status: true,
          message: 'Bundle aggiunto con successo',
          success: true
        });
        testSubscriptionsMutate({...testSubscriptions});
        appContext._setters._setLoading(false);
      } else {
        setStatusForm({
          status: true,
          message: rs.Message,
          success: false
        });
        appContext._setters._setLoading(false);
      }
    });
  }
  
  function confirmUser(data) {
    const { UidCoach, PlayerType,  Role} = data;


    const payload = // role
      // ? { UidUser: player, Role: profile.Subscription.Role }
      {
        UidUser: player,
        UidCoach: UidCoach?.value ?? null,
        Role: Role ,
        UserType: Role === "COACH" ? 1 : PlayerType === "not_socio" ? 0 : 1
      };
      appContext._setters._setLoading(true);
    const call = stdCall.post(
      `app/club/confirmsubscription/${club}`,
      payload,
      (rs) => {
        if (rs.Success) {
          setStatusForm({
            status: true,
            message: 'Utente confermato con successo',
            success: true
          });
          appContext._setters._setLoading(false);
        } else {
          setStatusForm({
            status: true,
            message: rs.Message,
            success: false
          });
          appContext._setters._setLoading(false);

        }
      }
    );
  }

  const [showBundleList, setShowBundleList] = useState();

  function handleShowBundleList(){
    handlerBundleHistory();
  }

  const roleSelector = [
    {
      id: 'Role',
      label: 'Ruolo',
      type: 'radioGroup',
      layout: 'col-lg-12',
      defaultValue: profile?.Subscription?.Role,
      inputs: [
        {
          id: 'COACH',
          label: 'Allenatore',
          type: 'radio',
          group: 'Role'
        },
        {
          id: 'TECH_DIRECTOR',
          label: 'Direttore tecnico',
          type: 'radio',
          group: 'Role'
        }
      ]
    }
  ];

  useEffect(() => {
    setResourse(TheApp.Res[TheApp.Locale]);
  }, [resource]);


    // pass boolean value to AppContext to change main header z-index...
    useEffect(() => {
      if (!modal) {
        appContext._setters._setVisibleModal(false);
      } else {
        appContext._setters._setVisibleModal(true);
      }
    }, [modal, appContext._setters]);

  return (
    <>
      {(userLoading || coachesLoading || bundlesLoading || testSubscriptionsLoading)  && <LocalLoading />}
      <div className="container club-confirm-panel p-xs-inline-0">
        <div className="row">
          <div className="col-lg-12 p-xs-inline-0">
            <div className="wrapper-info">
              <div className='row'>
                <div className='col-8'>

                <h3>{profile?.FullName}</h3>
                {
                  profile?.Birthday && (
                    <h4 className="subscription-date">
                      <span style={{ fontWeight: 500 }}>Data nascita:{' '}</span>
                      <strong>{profile?.Birthday}</strong>
                    </h4>
                  )
                }
                </div>
                <div className='col-12 col-md-4'>
                <div
                  className="btn small secondary btn-bundle-history"
                  onClick={handleShowBundleList}>
                  Vedi storico bundle
                </div>
                    </div>
              </div>
            
              <ul className="personal-info">
                {(profile?.AddressStreet || profile?.AddressTown) && (
                  <li className="playerLocation">
                    {profile?.AddressStreet}, {profile?.AddressTown} ({profile?.AddressZip})
                  </li>
                )}
                {profile?.BirthPlace && (
                  <li className="playerBirthPlace">
                    {profile?.BirthPlace}
                  </li>
                )}
                {profile?.Sex && (
                  <li className="playerSex">
                    {profile?.Sex}
                  </li>
                )}
                {profile?.Subscription?.Coach && (
                  <li className="coachName">
                    {profile.Subscription.Coach.FullName}
                  </li>
                )}
                {profile?.FiscalNumber && (
                  <li className="playerFiscalNumber">
                    {profile?.FiscalNumber}
                  </li>
                )}
              </ul>
            </div>
            
          </div>
        </div>
        {TheApp.CurrentContext.userSession.userData.myProfile.Subscriptions[0].Role === 'CLUB' && (
          <div className='row'>
            <div className="col-lg-5 p-xs-inline-0">
              {profile && (
                <>
                  <div
                    className="row"
                    style={{
                      margin: '1rem 0 0 0'
                    }}>
                    <div
                      className={`col-12 ${profile.Subscription.SubscriptionStatus === 1
                        ? 'user-active'
                        : 'user-not-active'
                        }`}>
                      <div>
                        {' '}
                        Data di iscrizione:{' '}
                        <strong>{profile.Subscription.DateOfSubscription}</strong>
                      </div>
                      <div>Email: <strong>{profile.Email}</strong></div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(confirmUser)}>
                    {profile?.Subscription.Role === 'PLAYER' ? (
                      <div className="row">
                        {inputsApprove.map((input) => (
                          <SingleInput
                            key={input.id}
                            errors={errors}
                            register={register}
                            control={control}
                            setValue={setValue}
                            {...input}
                          />
                        ))}
                      </div>
                    ) : (
                        <div className="row">
                          {roleSelector.map((input) => (
                            <SingleInput
                              key={input.id}
                              errors={errors}
                              register={register}
                              control={control}
                              setValue={setValue}
                              {...input}
                            />
                          ))}
                        </div>
                    )}
                    <div className="col-12">
                      <input
                        //value={'Salva'}
                        value={`${profile.Subscription.SubscriptionStatus === 1
                          ? 'Aggiorna'
                          : 'Attiva'
                          }`}
                        type="submit"
                        className="btn primary"
                        style={{ marginTop: '.75rem' }}
                      />
                    </div>
                  </form>
                
                  {profile?.Subscription?.SubscriptionStatus === 1 && (
                    <div className="row">
                      <div className="col-12 p-xs-inline-0">
                        <div
                          className="btn remove "
                          style={{ marginTop: '10px', textAlign: 'center' }}
                          onClick={() => changeModalVisibility({label: `Sei sicuro di voler annullare la sottoscrizione di ${profile?.FullName} ?`, callback: handleDelSubscription})}>
                          Annulla sottoscrizione
                        </div>
                      </div>
                    </div>
                  )}
                  

                  {/* <div
                    className={`wrapper-message to-appear ${
                      statusForm.status
                        ? statusForm.success
                          ? 'alert-success'
                          : 'alert-danger'
                        : ''
                    } ${statusForm.status ? 'visible' : ''}`}>
                    {statusForm.status && <p>{statusForm.message}</p>}
                  </div> */}
                </>
              )}
            </div>
            {
              profile?.Subscription?.SubscriptionStatus === 1 && (
                <div className='col-lg-7 p-xs-inline-0' style={{marginTop:"1rem"}}>
                  <h2
                    className="bundles-title"
                    style={{ marginBottom: '.5rem', display: 'block' }}>
                    Seleziona bundle da aggiungere
                  </h2>
                    <Select
                    isClearable={true}
                    onChange={handleBundleChange}
                    placeholder={"Seleziona bundle"}
                    options={bundleOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderWidth: 2,
                        padding: '0.5rem',
                        textTransform: 'uppercase',
                        borderColor: "#162CA1",
                        color: "#162CA1"
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 100,
                        color: "#162CA1",
                        textTransform: "uppercase",
                        fontWeight: 500
                      })
                }}/>
                  <form onSubmit={handleSubmitBundle(handleAddBundle)}>
                    <div className="row mt-4 mb-4" style={{gap: 'var(--bs-gutter-x) 0'}}>
                      {
                        selectedBundle?.Items?.map((item,i) => (
                          <div key={`${item.UidBundle}${i}`} className="col-sm-6 mb-xs-4 p-xs-inline-0">
                            <div className="action-test-container">
                              <p className="test-title">
                                {
                                  faStarRegular && (
                                    <img className="icon-accordion icon-accordion-test" src={faStarRegular} alt="" role="presentation" draggable="false" />
                                  )
                                }
                                <b>{item?.NumberOfTestPerPlayer} {item?.TestType}</b>
                              </p>
                              <ul>
                              {
                                item?.Items?.map(exercise => (
                                  <li key={`exercise-${exercise?.Exercise}`}> {exercise?.NumberOfExercises} {resource[`Lookup.ExerciseType.${exercise?.Exercise}`]}</li>
                                ))
                              }
                              </ul>
                              <hr className="bundle-sep mt-0 mb-3" />
                              <div className='bundle-footer'>
                                <h4 className="bundle-title">Valido fino al: <strong>{`${new Date(selectedBundle?.ValidityEndDate).toLocaleDateString("it-it")}`}</strong></h4>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    <button
                      disabled={bundleOptions?.length == 0}
                      type={bundleOptions?.length > 0 ? 'submit' : 'button'}
                      className={`btn primary`}
                      style={{ marginTop: '.75rem' }}
                    >
                      AGGIUNGI BUNDLE
                    </button>
                  </form>

                  <div className='bundles-container' style={{ marginTop:"25px" }}>
                    <header className="bundles-header">
                      <h2 className="bundles-title">Bundle aggiunti</h2>
                      <p className="bundle-desc">
                        Puoi consultare il dettaglio di ciascun bundle espandendolo.
                      </p>
                    </header>
                  {
                    testSubscriptions && testSubscriptions?.Items?.map((item) => (
                        <Accordion key={item?.Uid}>
                          <AccordionSummary sx={{
                            fontWeight:"bold",
                              '&.Mui-expanded': {
                                // backgroundColor:"#00daff !important",
                                // borderRadius:"7px"
                              }
                            }}>
                              <h3 className="bundle-title">{`${item?.TestBundle?.BundleName}`}</h3>
                            {
                              faAngleDownSolidAcc && (
                                <img src={faAngleDownSolidAcc} alt="" draggable="false" role="presentation" className="icon-accordion icon-accordion-open" />
                              )
                            }
                          </AccordionSummary>
                          <AccordionDetails style={{display:"flex"}}>
                            <div className='row'>
                              <div className="col-lg-12 p-xs-inline-0">
                                <hr className="bundle-sep mt-0 mb-3" />
                              </div>
                              {
                                item?.TestBundle?.Items?.map((test, i) => {
                                  return (
                                    <div key={`${test?.TestType}-${i}`} className='col-sm-6 mb-xs-4 p-xs-inline-0'>
                                      <div className="action-test-container">
                                        <p className="test-title">
                                          {
                                            faStarRegular && (
                                              <img className="icon-accordion icon-accordion-test" src={faStarRegular} alt="" role="presentation" draggable="false" />
                                            )
                                          }
                                          <b>
                                            {test?.NumberOfTestPerPlayer} {test?.TestType}
                                          </b>
                                        </p>
                                        {
                                          test?.Items?.length && (
                                            <ul>
                                              {
                                                test.Items.map((exercise, i) => (
                                                  <li key={`${exercise?.Exercise}-${i}`}>
                                                    {exercise?.NumberOfExercises} {resource[`Lookup.ExerciseType.${exercise?.Exercise}`]}
                                                  </li>
                                                ))
                                              }
                                            </ul>
                                          )
                                        }
                                      </div>
                                    </div>
                                  )})
                              }
                              <div className="col-lg-12 p-xs-inline-0">
                                <hr className="bundle-sep mt-3 mb-3" />
                              </div>
                              <footer className="bundle-footer col-lg-12 p-xs-inline-0">
                                <div className="row">
                                  <div className="col-sm-6 d-flex align-items-center mb-xs-4 p-xs-inline-0">
                                    <h3 className="bundle-title">Valido fino al: <strong>{`${new Date(item?.ValidityEndDate).toLocaleDateString("it-it")}`}</strong></h3>
                                  </div>
                                  <div className="col-sm-6 d-flex align-items-center p-xs-inline-0">
                                    <div className="bundle-cta-delete" onClick={() => changeModalVisibility({label: `Sei sicuro di voler rimuovere il bundle ${item?.TestBundle?.BundleName}?`, callback: handleDisableUserBundle, item: item})}>
                                      <span>Rimuovi questo bundle</span>
                                      {
                                        (item?.Available) && (
                                          <button style={{border:"none", cursor:"pointer", backgroundColor:"transparent"}}>
                                            <img style={{backgroundColor:"transparent"}} src={faTrashSolid}/>
                                          </button>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              </footer>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    )
                  }
                  </div>
                  
                </div>

              )
            }
            
          </div>
        )}
      </div>
      {modal && (
        <>
          <div className="modal-approve-test">
            <article className="modal-body">
              <div
                className="modal-close"
                onClick={(e) => {
                  setModal(false);
                }}
              >
                <i className="fa-solid fa-circle-xmark"></i>
              </div>
              <h2 className="modal-title">
                {
                  modalData?.label
                }
              </h2>
              <form
                className="modal-btns"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <button
                  className="btn primary btn-approve-test"
                  onClick={(e) => {
                    setModal(false);
                    modalData?.callback(e, modalData?.item ?? null);
                  }}
                >
                  Sì
                </button>
                <button
                  className="btn secondary btn-modal-close"
                  onClick={(e) => {
                    setModal(false);
                  }}
                >
                  No
                </button>
              </form>
            </article>
          </div>
        </>
      )}
    </>
  );
};
