import { useEffect, useState } from 'react';
import Select from 'react-select';

export function FilterByCoach({ coaches, handleFilerByCoach, defaultValue }) {
  const [coachesList, setCoachesList] = useState([]);

  const [resource, setResourse] = useState(TheApp?.Res);

  useEffect(() => {
    if (coaches) {
      setCoachesList(
        coaches?.Items?.map((coach) => {
          return {
            Value: coach.UidCoach,
            Label:  coach?.IsTechDirector ? coach?.FullName + "  (DIRETTORE TECNICO)" : coach.FullName
          };
        })
      );
    }
  }, [coaches]);
  const coachSel = coachesList?.map((coach) => {
    return {
      value: coach.Value,
      label: coach.Label
    };
  });
  const optionCoach = [{ value: 'all', label: `${resource['Form.SelectAll']}`}, ...coachSel];
  const selectFilterCoach = [
    {
      id: 'UidCoach',
      placeholder: 'Seleziona un allenatore',
      type: 'select',
      options: optionCoach || [],
      layout: 'col-lg-3'
    }
  ];

  useEffect(() => {
    //if the language has been changed, I update the resource
    setResourse(TheApp?.Res[TheApp.Locale]);
  }, [resource]);
  
  return (
    <>
      {selectFilterCoach.map((input) => {
        return (
          <Select
            key={input.id}
            defaultValue={defaultValue}
            options={input.options}
            placeholder={input.placeholder}
            label={input.label}
            onChange={handleFilerByCoach}
            styles={{
              control: (base) => ({
                ...base,
                borderWidth: 2,
                borderColor: '#1976D2',
                padding: '8px 0',
                color: '#1976D2',
                height: 'auto'
              })
            }}
          />
        );
      })}
    </>
  );
}
