import './auth.scss';
import parse from 'html-react-parser';

import { useForm } from 'react-hook-form';
import { useStandardCall } from '../../hooks/useStandardCall';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../hooks/auth/useAppContext';
import SingleInput from '../../components/SingleInput';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
export function Login() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();
  const { state } = useLocation();
  const returnUrl = state;
  const appContext = useContext(AppContext);
  const [emailConfirmRequest, setEmailConfirmRequest] = useState(null);
  const [error, setError] = useState({
    status: false,
    message: null,
    code: null
  });
  const stdCall = useStandardCall();
  const navigate = useNavigate();
  const [resource, setResourse] = useState(TheApp.Res);
  const locale = TheApp.Locale.substr(0, 2).toLowerCase();
  const page_token = new URLSearchParams(window.location.search).get(
    'page_token_id'
  );
  useEffect(() => {
    setResourse(TheApp.Res[TheApp.Locale]);
  }, [resource]);
  //define inputs array
  const inputs = [
    {
      id: 'UserName',
      label: `${resource['Form.Email']}`,
      type: 'email',
      defaultValue: '',
      validationSchema: {
        required: `${resource['Form.RequiredLabel']}`,
        pattern: {
          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          message: 'Email non valida'
        }
      },
      placeholder: `${resource['Form.EmailPlaceholder']}`,
      icon: 'fa fa-user',
      layout: 'col-lg-12'
    },

    {
      id: 'Pwd',
      label: 'Password',
      type: 'password',
      defaultValue: '',
      validationSchema: {
        required: `${resource['Form.RequiredLabel']}`
      },
      placeholder: resource['Form.PwdPlaceholder'],
      icon: 'fa fa-lock',
      layout: 'col-lg-12'
    }
  ];
  useEffect(() => {
    if (error.status) {
      toast.error(error.message);
    }
  }, [error]);
  function onSubmitDoLogin(data) {
    appContext._setters._setLoading(true);
    const rq = {
      ...data,
      setAsPrincipal: true
    };
    let call = stdCall.post('auth/login', rq, (rs) => {
      let url = returnUrl ? returnUrl : `${locale}/dashboard`;
      appContext._setters._setLoading(true);
      if (rs.Success) {
        setError({
          status: false,
          message: null,
          code: null
        });
        appContext._setters._setLoading(false);
        appContext._setters._setUserSession(rs.Session);
        navigate(`/${url}`);
      } else {
        setError({
          status: true,
          message: rs.Message,
          code: rs.StatusCode
        });
        appContext._setters._setLoading(false);
      }
    });
  }
  function handlerResendEmail(data) {
    let rq = {
      UserName: data.UserName,
      client_action_url: `${window.location.origin}/${locale}/auth/login`
    };
    let call = stdCall.post('auth/sendativationlink', rq, (rs) => {
      appContext._setters._setLoading(true);
      if (rs.Success) {
        appContext._setters._setLoading(false);
        setError({
          status: false,
          message: null,
          code: null
        });
        setEmailConfirmRequest({
          status: true,
          message:
            'Ti abbiamo reinviato la mail di conferma, controlla anche nello spam.'
        });
      }
    });
  }
  if (appContext.userSession) return <Navigate to={`/${locale}/dashboard`} />;
  return (
    <>
      <div
        className="page login-page"
        style={{ position: 'relative' }}>
        <div className="bg-before"></div>
        <div className="bg-after"></div>
        <div className="wrapper-auth login">
          <div className="wrapper-logo-form">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo-footer.png`}
              alt="Hyper Tennis"
            />
          </div>
          <div className="wrapper-form">
            <div className="wrapper-title">
              <h1>Login</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmitDoLogin)}>
              <div className="row">
                {inputs.map((input) => (
                  <SingleInput
                    key={input.id}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    {...input}
                  />
                ))}
              </div>
              <input
                type="submit"
                value={`Login`}
                className="btn primary"
              />
            </form>
            <a
              href={`/${locale}/reset-password`}
              className="reset-pwd">
              {resource['Login.ForgetPwdLabel']}
            </a>
            <a
              href={`/${locale}/account/signup`}
              className="sign-up">
              {resource['Login.SignUpLabel']}
            </a>
            {page_token && (
              <>
                <div className="wrapper-message alert-success">
                  {resource['Login.AccountConfirmedMessage']}
                </div>
              </>
            )}
            {emailConfirmRequest && (
              <div className="wrapper-message alert-success">
                {emailConfirmRequest.message}
              </div>
            )}
            {error.status && (
              <>
                {error.code === 412 && (
                  <div className="wrapper-message alert-danger">
                    L'account non è stato confermato.{' '}
                    <span
                      className="action-resend"
                      onClick={handleSubmit(handlerResendEmail)}>
                      Reinvia email di conferma.
                    </span>
                  </div>
                )}
                {error.code === 401 && (
                  <div className="wrapper-message alert-danger">
                    Error login
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
