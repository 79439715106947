import { useForm } from 'react-hook-form';
import SingleInput from '../../../components/SingleInput';
import { useContext, useEffect, useState } from 'react';
import { useStandardCall } from '../../../hooks/useStandardCall';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { toast } from 'react-toastify';

export function SenderMesssage({ inputs, player, handlerShowForm, UidClub }) {
  const appContext = useContext(AppContext);
  const [statusForm, setStatusForm] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm();
  const stdCall = useStandardCall();
  function onSendMessage(data) {
    const rq = {
      UidClub: UidClub,
      UidRecipient: player,
      Subject: data.Subject,
      Content: data.Content
    };
    appContext._setters._setLoading(true);
    let call = stdCall.post('app/messages', rq, (rs) => {
      if (rs.Success) {
        setStatusForm({
          status: true,
          success: true,
          message: 'Messaggio inviato correttamente'
        });
        appContext._setters._setLoading(false);
        reset();
      } else {
        setStatusForm({
          status: true,
          success: false,
          message: rs.Message
        });
        appContext._setters._setLoading(false);
      }
    });
  }


  useEffect(() => {
    if (statusForm) {
      if (statusForm?.success) {
        const toasts = toast.success(statusForm?.message);
      }
      if (!statusForm?.success) {
        const toasts = toast.error(statusForm?.message);
      }
    }
  }, [statusForm]);

  return (
    <div className="wrapper-sender-message">
      <div className="wrapper-sender-message__header">
        <div className="wrapper-sender-message__header__title">
          <h3>Invia un messaggio</h3>
        </div>
        <div
          className="btn-close"
          onClick={() => {
            handlerShowForm((prev) => ({
              show: !prev.show,
              player: null
            }));
          }}>
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
      </div>
      <div className="wrapper-form">
        <form onSubmit={handleSubmit(onSendMessage)}>
          <div className="row">
            {inputs.map((input) => (
              <SingleInput
                key={input.id}
                errors={errors}
                register={register}
                control={control}
                setValue={setValue}
                {...input}
              />
            ))}
          </div>
          <input
            value={'invia messaggio'}
            type="submit"
            className="btn primary"
          />
        </form>
        <div
          className={`wrapper-message to-appear ${
            statusForm?.status
              ? statusForm?.success
                ? 'alert-success'
                : 'alert-danger'
              : ''
          } ${statusForm?.status ? 'visible' : ''}`}>
          {statusForm?.status && <p>{statusForm?.message}</p>}
        </div>
      </div>
    </div>
  );
}
