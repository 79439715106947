import { Navigate, Route, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../hooks/auth/useAppContext';

export function ProtectedRoute({ component: Component, ...rest }) {
  const locale = TheApp.Locale.substr(0, 2).toLowerCase();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = `?${searchParams.toString()}`;
  const appContext = useAppContext();
  return appContext?.userSession ? (
    <Component {...rest} />
  ) : (
    <Navigate
      /* to="/auth/login" */
      to={`/${locale}/auth/login?ReturnUrl=${rest.path}${queryString ? queryString : ''}`}
      // to={`/auth/login?ReturnUrl=${rest.path}`}
      state={`${rest.path}${queryString}`}
    />
  );
}
