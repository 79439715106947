import { useContext, useEffect, useRef, useState } from "react";
import { LocalLoading } from "../../../components/Loading";
import { useSWRFetcher } from "../../../hooks/useStandardFetch";
import { useStandardCall } from "../../../hooks/useStandardCall";

import { AppContext } from "../../../hooks/auth/useAppContext";
import { useSearchParams } from "react-router-dom";

const TESTSESSION_READYFORCOACH = 5;

export function TestDetail({ details, resetDetailTest, player = null, role }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { UidPlayer } = player || {};
  const { show, test } = details;
  const { Uid, UidPlayer: playerUid, PlayerFullName } = test;
  const [user, setUser] = useState(UidPlayer ? UidPlayer : playerUid);
  const urlToFetch =
    (role === "COACH" || role === "TECH_DIRECTOR")
      ? user
        ? `app/test/${Uid}?uidPlayer=${user}&uidClub=${test.UidClub}`
        : `app/test/${Uid}`
      : `app/test/${Uid}`;
  const { data, isLoading, error, mutate } = useSWRFetcher(urlToFetch);
  const urlConfig =
    role === "PLAYER"
      ? `app/test/${Uid}/config`
      : `app/test/${Uid}/config?uidPlayer=${user}&uidClub=${test.UidClub}`;
  const {
    data: session,
    loading: testsLoading,
    errror: testError,
  } = useSWRFetcher(urlConfig);
  const [TestSession, setTestSession] = useState(null);
  const [appConfig, setAppCofig] = useState(null);
  const [exercise, setExercise] = useState(0);
  const [note, setNote] = useState();
  const [coachNote, setCoachNote] = useState();
  const stdCall = useStandardCall();
  const [localLoading, setLocalLoading] = useState(false);
  const [video3d, setVideo3d] = useState(null);
  const closeBtn = useRef(null);
  const [modal, setModal] = useState(false);
  const appContext = useContext(AppContext);
  const [video3dVisible, setVideo3dVisible] = useState(false);
  const [playerVisible, setPlayerVisible] = useState(true);
  var VERSION = "v0.91";

  function confirmAprroval() {
    setModal(true);
  }

  function handleFormNote(event, status) {
    event.preventDefault();
    let uidEx = session?.ConfigExercises?.[exercise].Uid;
    const rq = {
      Uid,
      UidPlayer: user,
      UidClub: test.UidClub,
      Status: status,
      CoachNote: coachNote,
      Exercises: [
        {
          Uid: uidEx,
          CoachNote: note,
        },
      ],
    };
    setLocalLoading(true);
    let call = stdCall.post(`app/test/${Uid}`, rq, (rs) => {
      if (rs.Success) {
        console.log("success");
        console.log(rs);
        setLocalLoading(false);
        if (status === 6) {
          closeBtn.current.click();
        }
      } else {
        console.log("error");
        console.error(rs);
      }
    });
  }

  /* console.log(test); */
  useEffect(() => {
    if (data) {
      // console.log(session);
      setTestSession(data.TestSession);
      if (session) {
        setAppCofig(session?.ConfigExercises?.[exercise].Config);
        setNote(data.TestSession.Exercises[exercise].CoachNote);
        setCoachNote(data.TestSession.CoachNote);
        mutate();
      }
    }
  }, [VERSION, data, exercise, mutate, session]);

  useEffect(() => {
    if (session) {
      if (appConfig) {
        document.querySelector("canvas")?.remove();
        var page;
        page = Player.factory("PagePlayer");
        page.init(appConfig);
      }
    }
  }, [session, appConfig, playerVisible]);

  // pass boolean value to AppContext to change main header z-index...
  useEffect(() => {
    if (!modal) {
      appContext._setters._setVisibleModal(false);
    } else {
      appContext._setters._setVisibleModal(true);
    }
  }, [modal, appContext._setters]);
  function setVideo3dHandler() {
    console.log("setVideo3dHandler");
    const url = `https://${window.location.hostname}${session.ConfigExercises[exercise].Config.video_3d}`;
    setTimeout(() => {
      setVideo3d(url);
    }, 500);
  }
  return (
    <>
      <div className="wrapper-test-detail">
        {isLoading ? <LocalLoading /> : null}
        <div
          className="btn-close"
          ref={closeBtn}
          onClick={() => {
            setSearchParams({});
            resetDetailTest((prev) => ({
              show: !prev.show,
              test: null,
            }));
          }}
        >
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
        {data && TestSession && (
          <div className="row">
            <div className="col-lg-12">
              <div className="test-detail-coach-actions">
                {PlayerFullName && (
                  <h1 className="player-name-title">
                    <i className="fa-solid fa-circle-user"></i>
                    <span>{PlayerFullName}</span>
                  </h1>
                )}
                {TestSession.Status === TESTSESSION_READYFORCOACH &&
                  role === "COACH" && (
                    <div
                      className="btn primary btn-approve-test"
                      onClick={confirmAprroval}
                    >
                      <i className="fa-solid fa-check"></i> Approva test
                    </div>
                  )}
              </div>
              <div className="test-summary">
                <header className="club-infos">
                  <div className="single-club-info">
                    <i className="fa-solid fa-location-dot"></i>{" "}
                    <span>{TestSession.ClubName}</span>
                  </div>
                  <div className="single-club-info type-test">
                    <span>
                      {TestSession.TestName} {TestSession.TestDurationMin}{" "}
                      minuti
                    </span>
                  </div>
                  <div className="single-club-info">
                    <i className="fa-regular fa-calendar-check"></i>{" "}
                    <span>{TestSession.Date}</span>
                  </div>
                </header>
                <hr className="test-summary-sep" />
                <section className="btn-wrapper test-summary-btn-wrapper">
                  {TestSession.Exercises.map((ex, i) => (
                    <div
                      className={`btn ${i === exercise ? "active" : ""}`}
                      key={ex.Uid}
                      onClick={() => {
                        setExercise(i);
                        setNote(ex.CoachNote);
                        setVideo3dVisible(false);
                        setPlayerVisible(true);
                        setVideo3d(null);
                      }}
                    >
                      {ex.ExerciseLabel}
                    </div>
                  ))}
                </section>
              </div>
            </div>
            <div className="row">
                  <div className={session?.ConfigExercises?.[exercise]?.Config?.video_3d ? "col-6" : "col-12"}>
                    <a target="_blank" href={`/Pages/getpdf.aspx?Uid=${test.Uid}`} className="btn">
                      Visualizza report
                    </a>
                  </div>
              {session?.ConfigExercises?.[exercise]?.Config?.video_3d && (
            <div className="col-lg-6">
                <div
                  className="btn"
                  onClick={() => {
                    setVideo3dHandler();
                    setVideo3dVisible(true);
                    setPlayerVisible(false);
                  }}
                  style={{ marginBottom: 20 }}
                >
                  Video 3d
                </div>
              </div>
              )}
              
              {video3d && video3dVisible && (
                <div className="video-3d" style={{ position: "relative" }}>
                  <div
                    className="btn-close"
                    onClick={() => {
                      setVideo3dVisible(false);
                      setPlayerVisible(true);
                      setVideo3d(null);
                    }}
                    style={{ zIndex: 1000 }}
                  >
                    <i className="fa-solid fa-circle-xmark"></i>
                  </div>
                  <video controls style={{ width: "100%" }}>
                    <source src={video3d} />
                  </video>
                </div>
              )}
            
            </div>
            <div className="col-lg-12">
              <div
                className={`video-holder js-video-holder ${
                  playerVisible ? "visible" : "hidden"
                }`}
                id="video-holder"
              >
                <div className="video-placeholder">
                  <div className="loading local">
                    <div className="loading-spinner"></div>
                  </div>
                </div>
                <div
                  className="layer layer-video-alert js-layer-video-alert"
                  data-type="type-0"
                  style={{ opacity: 0 }}
                >
                  <div className="layer-bg js-layer-bg"></div>
                  <div className="layer-video-alert-inner">
                    <div className="close js-close">&times;</div>
                    <div className="layer-body">
                      <div className="layer-icon js-layer-icon"></div>
                      <h2 className="layer-title js-layer-title">
                        Titolo lorem ipsum dolor sequitur!
                      </h2>
                      <div className="layer-text js-layer-text">
                        Tramite utilizzo di videocamere, radar e sensori
                        indossabili
                      </div>
                      <div className="btns-cont">
                        <div className="btn btn-primary js-learn-more">
                          Per saperne di più
                        </div>
                        <div className="btn btn-secondary js-learn-more">
                          Chiudi
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="library" style={{ display: "none" }}>
                <div id="template-plotty" className="plotty-area">
                  <div className="load-plotty">Loading...</div>
                  <div className="vhelper"></div>
                </div>
              </div>
            </div>
            {TestSession.Status === 5 && role === "COACH" ? (
              <>
                {localLoading && <LocalLoading />}
                <div className="col-lg-12">
                  <div className="test-summary">
                    <hr className="test-summary-sep notes-sep" />
                    <section className="test-summary-note">
                      <form
                        onSubmit={(e) => {
                          handleFormNote(e, 5);
                        }}
                      >
                        <h3>
                          <i className="fa-regular fa-note-sticky"></i> Note
                          esercizio
                        </h3>
                        <textarea
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          name="nota"
                          style={{ width: "100%", minHeight: 40 }}
                          rows={3}
                          cols={4}
                        />
                        <button type="submit" className="btn primary">
                          salva nota
                        </button>
                      </form>
                    </section>
                  </div>
                </div>
              </>
            ) : (
              <>
                {TestSession.Exercises[exercise].CoachNote && (
                  <>
                    <div className="col-lg-12">
                      <div className="test-summary">
                        <hr className="test-summary-sep notes-sep" />
                        <section className="test-summary-note">
                          <h3>
                            <i className="fa-regular fa-note-sticky"></i> Note
                            esericizio
                          </h3>
                          <p>{TestSession.Exercises[exercise].CoachNote}</p>
                        </section>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {role === "COACH" ? (
              <div className="col-lg-12">
                <div className="test-summary">
                  <section className="test-summary-note">
                    <h3>
                      <i className="fa-regular fa-note-sticky"></i> Note di
                      sessione
                    </h3>
                    <form
                      onSubmit={(e) => {
                        handleFormNote(e, TestSession.Status);
                      }}
                    >
                      <textarea
                        value={coachNote}
                        onChange={(e) => setCoachNote(e.target.value)}
                        name="nota"
                        rows={4}
                        cols={50}
                      />
                      <button type="submit" className="btn primary">
                        salva nota
                      </button>
                    </form>
                  </section>
                </div>
              </div>
            ) : (
              <div className="col-lg-12">
                <div className="test-summary">
                  <section className="test-summary-note">
                    <h3>
                      <i className="fa-regular fa-note-sticky"></i> Note di
                      sessione
                    </h3>
                    <p>{TestSession.CoachNote}</p>
                  </section>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {modal && (
        <>
          <div className="modal-approve-test">
            <article className="modal-body">
              <div
                className="modal-close"
                onClick={(e) => {
                  setModal(false);
                }}
              >
                <i className="fa-solid fa-circle-xmark"></i>
              </div>
              <h2 className="modal-title">
                Vuoi confermare l'approvazione del test?
              </h2>
              {PlayerFullName && (
                <p>
                  Cliccando sul pulsante <strong>Sì</strong> confermerai il test
                  di <strong>{PlayerFullName}.</strong>
                </p>
              )}
              <form
                className="modal-btns"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <button
                  className="btn primary btn-approve-test"
                  onClick={(e) => {
                    handleFormNote(e, 6);
                    setModal(false);
                  }}
                >
                  Sì
                </button>
                <button
                  className="btn secondary btn-modal-close"
                  onClick={(e) => {
                    setModal(false);
                  }}
                >
                  No
                </button>
              </form>
            </article>
          </div>
        </>
      )}
    </>
  );
}
