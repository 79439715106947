/* eslint-disable */
import { useEffect, useState, createContext, useContext } from 'react';
import { useStandardCall } from '../useStandardCall';

// the context
export const AppContext = createContext(null);

export function useAppContext() {

  
  const [loading, setLoading] = useState(false);
  const [userSession, setUserSession] = useState(
    TheApp.CurrentContext.userSession ?? null
  );
  const [profile, setProfile] = useState(
    TheApp.CurrentContext.userSession
      ? TheApp.CurrentContext.userSession.userData.myProfile
      : null
  );
  const [locale, setLocale] = useState(TheApp.Env.Locale);
  const [hasVisibleModal, setHasVisibleModal] = useState(false);  
  //
  function _setUserSession(session) {
    //alert("HHHH");
    TheApp.CurrentContext.userSession = session;
    TheApp.CurrentContext.Bearer = session ? session.Bearer : null;
    TheApp.CurrentContext.IsAuthenticated = session !== null;
    setUserSession(session);
  }
  // DA controllare
  function _setLocale(locale) {
    TheApp.Env.Locale = TheApp.Locale = locale;
    TheApp.Env.ContentLocale = TheApp.ContentLocale = locale;
    TheApp.Env.LocaleShort = locale.substr(0, 2).toLowerCase();
    TheApp.Res.current = locale; // modificato  TheApp.Env.Res.current non esiste
    setLocale(locale);
  }
  function _setProfile(profile) {
    TheApp.CurrentContext.userSession.userData.myProfile = profile;
    setProfile(profile);
  }

  function _setLoading(bool){
    setLoading(bool);
  }

  /**
   * Serve per controllare se una modale è visibile o meno
   * in quanto lo z-index della navigazione vince sempre rispetto a
   * quello della modale.
   */
  function _setVisibleModal(bool) {
    setHasVisibleModal(bool);
  }

  return {
    loading: loading,
    userSession: userSession,
    locale: locale,
    profile: profile,
    isAuthenticated: () => userSession !== null,
    hasVisibleModal: hasVisibleModal,
    // con _ sono private
    _setters: { _setUserSession, _setLocale, _setProfile, _setVisibleModal, _setLoading }
  };
}
