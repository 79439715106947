import './auth.scss';
import { useContext, useEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import { useForm } from 'react-hook-form';
import SingleInput from '../../components/SingleInput';
import { useStandardCall } from '../../hooks/useStandardCall';
import { useSWRFetcher } from '../../hooks/useStandardFetch';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppContext } from '../../hooks/auth/useAppContext';
export default function Registrazione() {
  const { data, error, isLoading } = useSWRFetcher(`app/club?format=lookup`);


  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors, isSubmitSuccessful }
  } = useForm();


  const appContext = useContext(AppContext);
  const {loading} = appContext;

  const [registerOk, setRegisterOk] = useState({
    status: false,
    message: null,
    success: false
  });
  const stdCall = useStandardCall();
  const locale = TheApp.Locale.substr(0, 2).toLowerCase();

  const [resource, setResourse] = useState(TheApp.Res);

  useEffect(() => {
    setResourse(TheApp.Res[TheApp.Locale]);
  }, [resource]);

  const inputs = [
    {
      id: 'FirstName',
      label: `${resource['Form.FirstName']}`,
      type: 'text',
      defaultValue: '',
      isRequired: true,
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      placeholder: `${resource['Form.FirstNamePlaceholder']}`,
      icon: 'fa fa-user',
      layout: 'col-lg-6'
    },
    {
      id: 'LastName',
      label: `${resource['Form.LastName']}`,
      type: 'text',
      isRequired: true,
      defaultValue: '',
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      placeholder: `${resource['Form.LastNamePlaceholder']}`,
      icon: 'fa fa-user',
      layout: 'col-lg-6'
    },
    {
      id: 'Email',
      label: `${resource['Form.Email']}`,
      type: 'email',
      isRequired: true,
      defaultValue: '',
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      placeholder: `${resource['Form.EmailPlaceholder']}`,
      icon: 'fa fa-envelope',
      layout: 'col-lg-12 col-md-6'
    },
    {
      id: 'Password',
      label: `${resource['Form.Password']}`,
      type: 'password',
      defaultValue: '',
      isRequired: true,
      validationSchema: {
        required: 'Campo obbligatorio',
        pattern: {
          value:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!-£$%&=<>|])([^\s]){8,20}$/,

          message:
            'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale'
        }
      },
      placeholder: `${resource['Form.PwdPlaceholder']}`,
      icon: 'fa fa-lock',
      layout: 'col-lg-6'
    },
    {
      id: 'ConfirmPassword',
      label: `${resource["Form.ConfirmPassword"]}`,
      type: 'password',
      defaultValue: '',
      toCompare: true,
      isRequired: true,
      validationSchema: {
        required: 'Campo obbligatorio',
        validate: {
          matchPreviousValue: (value) => {
            const { Password } = getValues();
            return Password === value || 'Le password non coincidono';
          }
        }
      },
      placeholder: `${resource['Form.PwdConfirmPlaceholder']}`,
      icon: 'fa fa-lock',
      layout: 'col-lg-6'
    },
    {
      id: 'Clubs',
      label: `${resource["Form.SelectClubs"]}`,
      type: 'select',
      isMulti: false,
      placeholder: `${resource["Form.SelectClubs"]}`,
      layout: 'col-lg-12 col-md-6',
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      options: data ? data : []
    },
    {
      id: 'IsCoach',
      label:  `${resource['Form.CheckBoxCoach']}`,
      type: 'checkbox',
      defaultValue: '',
      placeholder: `${resource['Form.CheckBoxCoach']}`,
      layout: 'col-lg-12 col-md-6'
    },
    {
      id: 'Privacy',
      label: 'Privacy Policy',
      type: 'checkbox',
      placeholder: 'Privacy Policy',
      defaultValue: '',
      validationSchema: {
        required: 'Campo obbligatorio'
      },
      layout: 'col-lg-12 col-md-6'
    }
  ];
  useEffect(() => {
    if (registerOk.message) {
      if (registerOk.success) {
        toast.success(registerOk.message);
      } else {
        toast.error(registerOk.message);
      }
    }
  }, [registerOk, reset]);
  const onSubmit = (data) => {
    /* e.preventDefault(); */
    const { ConfirmPassword, Clubs, ...rest } = data;
    const rq = {
      UidClub: Clubs.value,
      ...rest,
      client_action_url: `${window.location.origin}/${locale}/auth/login`
    };
    appContext._setters._setLoading(true);
    
    let call = stdCall.post('app/createaccount', rq, (rs) => {
      if (rs.Success) {
        appContext._setters._setLoading(false);
        reset();
        setRegisterOk({
          status: true,
          success: true,
          message:
            'Registrazione avvenuta con successo, verifica la tua casella di posta per confermare la registrazione.'
        });
      } else {
        appContext._setters._setLoading(false);
        setRegisterOk({
          success: false,
          status: true,
          message: rs.Message
        });
      }
    });
  };
  const navigate = useNavigate();
  if (isLoading) return <Loading />;
  return (
    <div className="page login-page">
      {loading && <Loading />}
      {data && (
        <>
          <div className="wrapper-auth signup">
            <div
              onClick={() => {
                navigate(`/${locale}/auth/login`);
              }}
              className="back-login">
              <i className="fa-solid fa-arrow-left"> {resource['Login.SignUpGoBack']}</i>
            </div>
            <div className="wrapper-logo-form">
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo-footer.png`}
                alt=""
              />
            </div>
            <div className="wrapper-form">
              <div className="wrapper-title">
                <h1>{`${resource["Form.SignUp"]}`}</h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  {inputs.map((input) => (
                    <SingleInput
                      key={input.id}
                      errors={errors}
                      register={register}
                      control={control}
                      setValue={setValue}
                      {...input}
                    />
                  ))}
                </div>
                <input
                  type="submit"
                  value={resource['Form.Submit']}
                  className="btn primary"
                />
              </form>
            </div>
            {registerOk.status && (
              <div
                className={`wrapper-message ${registerOk.success ? 'alert-success' : 'alert-danger'
                  }`}>
                {registerOk.status && <p>{registerOk.message}</p>}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
