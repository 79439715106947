import { useState } from 'react';
import { AnagraficaUser } from './user.data.component';
import { TechnicalProfile } from './technical.data.component';
import { UserSecurityTabComponent } from './user.security.tab.component';
import { AddSubscriptionClub } from './add.subscription.club.component';

export function ProfileTab({ myProfile }) {
  const [activeTab, setActiveTab] = useState('anagrafica');

  return (
    <>
      <div className="section-tab section-profile">
        <div className="tab-container-vertical">
          <div className="wrapper-tabs-controller">
            <div
              className={`tabs-controller ${
                activeTab === 'anagrafica' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('anagrafica')}>
              Anagrafica
            </div>
            <div
              className={`tabs-controller ${
                activeTab === 'dati-tecnici' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('dati-tecnici')}>
              Dati tecnici
            </div>
            <div
              className={`tabs-controller ${
                activeTab === 'add-subscription' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('add-subscription')}>
              i miei club
            </div>
            <div
              className={`tabs-controller ${
                activeTab === 'sicurezza' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('sicurezza')}>
              Sicurezza
            </div>
          </div>
        </div>
        <div className="wrapper-tabs wrapper-tabs-personal">
          <div
            className={`single-tab profile anagrafica ${
              activeTab === 'anagrafica' ? 'active' : ''
            }`}>
            <AnagraficaUser myProfile={myProfile} />
          </div>
          <div
            className={`single-tab profile dati-tecnici ${
              activeTab === 'dati-tecnici' ? 'active' : ''
            }`}>
            <TechnicalProfile myProfile={myProfile} />
          </div>
          <div
            className={`single-tab profile add-subscription ${
              activeTab === 'add-subscription' ? 'active' : ''
            }`}>
            <AddSubscriptionClub myProfile={myProfile} />
          </div>
          <div
            className={`single-tab profile sicurezza ${
              activeTab === 'sicurezza' ? 'active' : ''
            }`}>
            <UserSecurityTabComponent />
          </div>
        </div>
      </div>
    </>
  );
}
