
import { useEffect, useState } from "react";
import { LocalLoading } from "../../../components/Loading";
import { useSWRFetcher } from "../../../hooks/useStandardFetch";
import court from '../../../assets/tennis_court.jpg';
import ground from '../../../assets/tennis-ground.jpg';
import { motion } from "framer-motion";
import parse, { domToReact } from 'html-react-parser';


const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    }
};
const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};



export function SubscriptionsTab({ club }) {
    const { UidClub } = club;
    const { data, isLoading, error } = useSWRFetcher(`app/tests?uidClub=${UidClub}`);
    const [processingTests, setProcessingTests] = useState();
    const [avaibleTests, setAvaibleTests] = useState();
    const [coachApprovingTests, setCoachApprovingTests] = useState();
    const [resource, setResource] = useState(TheApp.Res);

    const [activeTab, setActiveTab] = useState('Available');
    let [tabs, setTabs] = useState([
        {
            id: 'Available',
            label: 'Disponibili',
        },
        {
            id: 'Processing',
            label: 'Processing',
        },
        {
            id: "AwaitingCoach",
            label: "Attesa COACH"
        }
    ])

    useEffect(() => {
        if (data) {
            setAvaibleTests(data.AvailableTests.filter((test) => test.Available === true));
            setProcessingTests(data.AvailableTests.filter((test) => test.Available === false));
            setCoachApprovingTests(data.AvailableTests.filter((test) => test.TestStatus === 5));
        }
    }, [data]);

    useEffect(() => {
        setResource(TheApp.Res[TheApp.Locale]);
    }, [resource]);


    const parser = {
        replace: ({ attribs, children }) => {
          if (!attribs) {
            return;
          }
          console.log(attribs);
            return (
                <strong>
                    {domToReact(children, parser)}
                </strong>
            )
        }
      };



    return (
        <div className="section-tab">
            {isLoading ? <LocalLoading /> : (
                <div className="container xs-p-0">
                    {data?.AvailableTests?.length === 0 && (
                        <div className="row">
                            <div className="col-lg-12 xs-p-0">
                                <div className="wrapper-message to-appear alert-warning visible">
                                    <p>
                                        {resource['Subscriptions.Tabs.EmptyAvailable']}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    {
                        data?.AvailableTests?.length > 0 && (
                            <>
                                <div className="tabs-container container" style={{ margin: "0", padding: "0" }}>
                                    <div className="wrapper-tabs-controller tabs-normal">
                                        {tabs.map((tab) => (
                                            tab && (
                                                <div
                                                    className={`tab-controller ${activeTab === tab.id ? 'active' : ''}`}
                                                    key={`${tab.id}-controller`}
                                                    onClick={() => setActiveTab(tab.id)}>
                                                    {resource[`Subscriptions.Tabs.${tab.id}`]}
                                                </div>
                                            )
                                        ))
                                        }
                                    </div>

                                    <div className="wrapper-tabs" style={{ marginTop: "-1rem" }}>
                                        {activeTab === 'Available' && (
                                            <div className="single-tab active">
                                                <div className="section-tab">
                                                    {
                                                        avaibleTests?.length > 0 ? (
                                                            <div className="row">
                                                                <div className="col-lg-12 xs-p-0">
                                                                    <div className="wrapper-message to-appear alert-primary visible">
                                                                        <p id="ciao">
                                                                            {
                                                                                parse(
                                                                                    resource['Subscriptions.Tabs.HasAvailable'].replace("{tests}", `${avaibleTests.length}`),
                                                                                    parser
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <motion.div
                                                                        variants={container}
                                                                        animate="visible"
                                                                        initial="hidden"
                                                                        className="wrapper-tests"
                                                                    >

                                                                        {avaibleTests.map((test) => (
                                                                            <motion.div
                                                                                variants={item}
                                                                                className="single-test row xs-p-0"
                                                                                key={test.Uid}>

                                                                                <div className="col-lg-3 xs-p-0">
                                                                                    <div className="wrapper-img" style={{ backgroundColor: '#162CA1' }}>
                                                                                        <img src={ground} alt="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-9 xs-p-0">
                                                                                    <div className="wrapper-info">
                                                                                        <h2 className="mb-3">
                                                                                            {test.TestType} {test.TestDurationMin} minuti
                                                                                            <span style={{ textTransform: "uppercase" }}>
                                                                                                <span className="label" style={{ marginRight: ".4rem" }}>
                                                                                                    <i className="fa-solid fa-hourglass-start" style={{ marginRight: ".3rem" }}></i> Valido fino al
                                                                                                </span>
                                                                                                {test.ValidityEndDate}
                                                                                            </span>
                                                                                        </h2>
                                                                                        <article className="single-test-card card-exercise mb-0">
                                                                                            <h3><i className="fa-solid fa-box"></i> {test.BundleName}</h3>
                                                                                        </article>
                                                                                        <article className="single-text-card card-exercise mb-0">
                                                                                            <h3><i className="fa-solid fa-list"></i> Esercizi</h3>
                                                                                            <ul className="exercise-list">
                                                                                                {test.Exercises.map((excersise) => (<li key={excersise.Uid}>
                                                                                                    {excersise.NumberOfExercises} {resource[`Lookup.ExerciseType.${excersise.Exercise}`]}
                                                                                                </li>))}
                                                                                            </ul>
                                                                                        </article>
                                                                                    </div>
                                                                                </div>
                                                                            </motion.div>
                                                                        ))}
                                                                    </motion.div>
                                                                </div>
                                                            </div>
                                                        )
                                                            :
                                                            (
                                                                <div className="row">
                                                                    <div className="col-lg-12 xs-p-0">
                                                                        <div className="wrapper-message to-appear alert-warning visible">
                                                                            <p>
                                                                            {resource['Subscriptions.Tabs.EmptyAvailable']}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {
                                            activeTab === "Processing" && (
                                                <div className="single-tab active">
                                                    <div className="section-tab">
                                                        {
                                                            processingTests?.length > 0 ? (
                                                                <div className="row">
                                                                    <div className="col-lg-12 xs-p-0">
                                                                        <div className="wrapper-message to-appear alert-warning visible">
                                                                            <p>
                                                                                {resource['Subscriptions.Tabs.HasProcessing']}
                                                                            </p>
                                                                        </div>
                                                                        <motion.div
                                                                            variants={container}
                                                                            animate="visible"
                                                                            initial="hidden"
                                                                            className="wrapper-tests"
                                                                        >
                                                                            {processingTests.map((test) => (
                                                                                <motion.div
                                                                                    variants={item}
                                                                                    className="single-test row xs-p-0"
                                                                                    key={test.Uid}>
                                                                                    <div className="col-lg-3 xs-p-0">
                                                                                        <div className="wrapper-img" style={{ backgroundColor: '#162CA1' }}>
                                                                                            <i className="fa-solid fa-spinner"></i>
                                                                                            <img src={court} alt="" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-9 xs-p-0">
                                                                                        <div className="wrapper-info">
                                                                                            <h2 className="mb-3">
                                                                                                {test.TestType} ({test.TestDurationMin} minuti)
                                                                                                <span style={{ textTransform: "uppercase" }}>
                                                                                                    <span className="label">
                                                                                                        <i className="fa-solid fa-bell" style={{ marginRight: ".3rem" }}></i>
                                                                                                    </span>
                                                                                                    {
                                                                                                        resource[`TESTSTATUS.${test.TestStatus}`]
                                                                                                    }
                                                                                                </span>
                                                                                            </h2>
                                                                                            <article className="single-test-card card-exercise mb-0">
                                                                                                <h3><i className="fa-solid fa-box"></i> {test.BundleName}</h3>
                                                                                            </article>
                                                                                            <article className="single-test-card card-exercise mb-0">
                                                                                                <h3><i className="fa-solid fa-list"></i> Esercizi</h3>
                                                                                                <ul className="exercise-list">
                                                                                                    {test.Exercises.map((excersise) => (<li key={excersise.Uid}>
                                                                                                        {excersise.NumberOfExercises} {resource[`Lookup.ExerciseType.${excersise.Exercise}`]}
                                                                                                    </li>))}
                                                                                                </ul>
                                                                                            </article>
                                                                                        </div>
                                                                                    </div>
                                                                                </motion.div>
                                                                            ))}
                                                                        </motion.div>
                                                                    </div>
                                                                </div>
                                                            )
                                                                :
                                                                (
                                                                    (
                                                                        <div className="row">
                                                                            <div className="col-lg-12 xs-p-0">
                                                                                <div className="wrapper-message to-appear alert-warning visible">
                                                                                    <p>
                                                                                        {resource['Subscriptions.Tabs.EmptyProcessing']}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            activeTab === "AwaitingCoach" && (
                                                <div className="single-tab active">
                                                    <div className="section-tab">
                                                        {
                                                            coachApprovingTests?.length > 0 ? (
                                                                <div className="row">
                                                                    <div className="col-lg-12 xs-p-0">
                                                                        <div className="wrapper-message to-appear alert-warning visible">
                                                                            <p>
                                                                            {resource['Subscriptions.Tabs.HasCoach']}
                                                                            </p>
                                                                        </div>
                                                                        <motion.div
                                                                            variants={container}
                                                                            animate="visible"
                                                                            initial="hidden"
                                                                            className="wrapper-tests"
                                                                        >

                                                                            {coachApprovingTests.map((test) => (
                                                                                <motion.div
                                                                                    variants={item}
                                                                                    className="single-test row xs-p-0"
                                                                                    key={test.Uid}>
                                                                                    <div className="col-lg-3 xs-p-0">
                                                                                        <div className="wrapper-img" style={{ backgroundColor: '#162CA1' }}>
                                                                                            <i className="fa-solid fa-spinner"></i>
                                                                                            <img src={court} alt="" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-9 xs-p-0">
                                                                                        <div className="wrapper-info">
                                                                                            <h2 className="mb-3">
                                                                                                {test.TestType} {test.TestDurationMin} minuti
                                                                                                <span style={{ textTransform: "uppercase" }}>
                                                                                                    {test.TestStatusLabel}
                                                                                                </span>
                                                                                            </h2>
                                                                                            <article className="single-test-card card-exercise mb-0">
                                                                                                <h3><i className="fa-solid fa-box"></i> {test.BundleName}</h3>
                                                                                            </article>
                                                                                            <article className="single-test-card card-exercise mb-0">
                                                                                                <h3><i className="fa-solid fa-list"></i> Esercizi</h3>
                                                                                                <ul className="exercise-list">
                                                                                                    {test.Exercises.map((excersise) => (<li key={excersise.Uid}>
                                                                                                        {excersise.NumberOfExercises} {resource[`Lookup.ExerciseType.${excersise.Exercise}`]}
                                                                                                    </li>))}
                                                                                                </ul>
                                                                                            </article>
                                                                                        </div>
                                                                                    </div>
                                                                                </motion.div>
                                                                            ))}
                                                                        </motion.div>
                                                                    </div>
                                                                </div>
                                                            )
                                                                :
                                                                (
                                                                    (
                                                                        <div className="row">
                                                                            <div className="col-lg-12 xs-p-0">
                                                                                <div className="wrapper-message to-appear alert-warning visible">
                                                                                    <p>
                                                                                        {resource['Subscriptions.Tabs.EmptyCoach']}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )
                                                        }

                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                            </>
                        )
                    }
                </div>
            )}
        </div>
    )
}