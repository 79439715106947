import "./dashboard.scss";
import parse, { domToReact } from "html-react-parser";
import { AppContext, useAppContext } from "../../hooks/auth/useAppContext";
import { useContext, useEffect, useState } from "react";
import { Header } from "./components/Header";
import { ProfileTab } from "./components/profile.tab.component";
import { ComunicationsTab } from "./components/comunications.tab.component";
import { TestTab } from "./components/test.tab.component";
import { PlayersTab } from "./components/players.tab.components";
import { CoachesListTab } from "./components/coaches.tab.component";
import Footer from "../../components/Footer";
import logoFooter from "../../assets/logo-footer.png";
import logoDev from "../../assets/3x1010.png";
import { SubscriptionsTab } from "./components/subscription.tab.component";
import { SuggestionsTab } from "./components/suggestions.tab.component";
import { CoachApprovalTest } from "./components/coach.approval.test";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
export default function Dashboard() {
  const appContext = useContext(AppContext);
  let lng = window.location.pathname.split("/")[1] ?? "it";
  const [currentLng, setCurrentLng] = useState(lng);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { userSession, profile } = appContext;
  const { userData } = userSession;
  const { myProfile } = userData;
  const [userInfo, setUserInfo] = useState({
    TechnicalProfile: myProfile.TechnicalProfile || {},
    Birthday: myProfile.Birthday || "",
  });

  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const { /* TechnicalProfile, */ /* Birthday, */ Subscriptions, Uid } =
    myProfile;
  const [club, setClub] = useState(Subscriptions[0]);
  const { Birthday, TechnicalProfile } = userInfo;
  const [resource, setResourse] = useState(TheApp.Res);
  const [activeTab, setActiveTab] = useState(null);

  const {
    Height,
    HeightUnit,
    Weight,
    WeightUnit,
    DominantHandLeft,
    DominantHandRight,
  } = TechnicalProfile;
  const [year, month, day] = Birthday.split("-"); //"23/05/1973"
  var dob = new Date(`${month}/${day}/${year}`);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();
  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);
  //extract year from date
  var year_dt = age_dt.getUTCFullYear();
  //now calculate the age of the user
  var age = year ? Math.abs(year_dt - 1970) : null;
  //define the tabs based on the user's role
  const tabs = [
    {
      id: "test",
      label: resource["Dashboard.Tabs.MyTests"],
      roles: ["PLAYER", "COACH", "TECH_DIRECTOR"],
    },
    {
      id: "subscription",
      label: "Subscriptions",
      roles: ["PLAYER"],
    },
    {
      id: "approval",
      label: resource["Dashboard.Tabs.Approval"],
      roles: ["COACH", "TECH_DIRECTOR"],
    },
    {
      id: "alievi",
      label: resource["Dashboard.Tabs.Players"],
      roles: ["CLUB", "TECH_DIRECTOR", "COACH"],
    },
    {
      id: "maestri",
      label: resource["Dashboard.Tabs.Coaches"],
      roles: ["CLUB", "TECH_DIRECTOR"],
    },
    {
      id: "comunicazioni",
      label: resource["Dashboard.Tabs.Communications"],
      roles: ["PLAYER", "COACH", "TECH_DIRECTOR"],
    },
    {
      id: "profile",
      label: resource["Dashboard.Tabs.Profile"],
      roles: ["PLAYER", "COACH", "TECH_DIRECTOR"],
    },
  ];
  //select the first club as default
  useEffect(() => {
    if (Subscriptions.length > 0) {
      setClub(Subscriptions[0]);
      setRole(Subscriptions[0].Role);
    }
  }, [Subscriptions]);

  useEffect(() => {
    // sets active tab based on query strings
    if (Subscriptions[0].Role !== "CLUB" && searchParams.get("test")) {
      setActiveTab("test");
    } else if (
      (Subscriptions[0].Role === "COACH" ||
        Subscriptions[0].Role === "TECH_DIRECTOR") &&
      searchParams.get("testapproval")
    ) {
      setActiveTab("approval");
    } else if (location.pathname == `/${currentLng}/suggestions`) {
      setActiveTab("suggestions");
    } else {
      setActiveTab(Subscriptions[0].Role === "CLUB" ? "alievi" : "test");
    }
  }, []);

  useEffect(() => {
    if (
      !(
        (activeTab === "approval" && searchParams.get("testapproval")) ||
        (activeTab === "test" && searchParams.get("test"))
      ) &&
      activeTab !== null
    ) {
      setSearchParams({});
    }
  }, [activeTab]);

  useEffect(() => {
    //if the language has been changed, I update the resource
    setResourse(TheApp.Res[TheApp.Locale]);
  }, [resource]);

  useEffect(() => {
    //if the profile has been updated, I also update the user's profile
    appContext._setters._setProfile(appContext.userSession.userData.myProfile);
    setUserInfo({
      Birthday: profile?.Birthday || "",
      TechnicalProfile: profile?.TechnicalProfile || {},
    });
  }, [appContext, profile]);

  //remap the clubs to make them compatible with the SingleInput select component
  const options = Subscriptions.map((subscription) => {
    return {
      value: subscription.UidClub,
      label: subscription.ClubName,
    };
  });
  const selectClubs = [
    {
      id: "club",
      label: "Seleziona il tuo club",
      type: "select",
      options: options,
    },
  ];
  //when the club changes update the role and the active tab
  function onChangeClub(data) {
    const { value } = data;
    let club = Subscriptions.find(
      (subscription) => subscription.UidClub === value
    );
    setClub(club);
    setRole(club.Role);
    setActiveTab(club.Role === "CLUB" ? "alievi" : "test");
  }
  const parser = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }
      if (attribs.id === "clubName") {
        return (
          <span>
            <strong>{club.ClubName}</strong>
            {domToReact(children, parser)}
          </span>
        );
      }
    },
  };

  useEffect(() => {
    if (location.pathname == `/${currentLng}/suggestions`) {
      setActiveTab("suggestions");
    }
  }, [location.pathname]);
  return (
    <>
      {userInfo && resource && (
        <div className="page-dashboard">
          <Header
            Height={Height > 0 ? Height : null}
            HeightUnit={HeightUnit}
            Weight={Weight > 0 ? Weight : null}
            WeightUnit={WeightUnit}
            userData={userData}
            club={club}
            dominantHand={
              DominantHandLeft === true
                ? "Sinistro"
                : DominantHandRight
                ? "Destro"
                : null
            }
            role={role}
            age={age}
            selectClubs={selectClubs}
            options={options}
            onChangeClub={onChangeClub}
            setActiveTab={setActiveTab}
          />
          {club?.SubscriptionStatus === 0 && (
            <div className="container xs-p-0">
              <div className="wrapper-message alert-warning">
                <p>
                  {parse(
                    `${resource["Dashboard.MessageWaitingAuthClub"]}`,
                    parser
                  )}
                </p>
              </div>
            </div>
          )}
          {club?.SubscriptionStatus === 2 && (
            <div className="container xs-p-0">
              <div className="wrapper-message alert-warning">
                <p>
                  {parse(
                    `${resource["Dashboard.MessageSubscriptionExpired"]}`,
                    parser
                  )}
                </p>
              </div>
            </div>
          )}
          <div className="tabs-container container">
            <div className="wrapper-tabs-controller">
              {tabs.map((tab) => {
                // se il ruolo dell'utente è incluso nell'array dei ruoli della tab, allora il tab controller viene renderizzata
                return (
                  tab.roles.includes(role) && (
                    <div
                      className={`tab-controller ${
                        (tab.id == "comunicazioni" && club.MessagesCount > 0) ||
                        (tab.id == "approval" && club.AwaitingCoachCount > 0)
                          ? "hasNotification"
                          : ""
                      } ${tab.id} ${activeTab === tab.id ? "active" : ""}`}
                      key={`${tab.id}-controller`}
                      onClick={() => {
                        setActiveTab(tab.id);
                        navigate(`/${lng}/dashboard`);
                      }}
                    >
                      {tab.id === "comunicazioni" && club.MessagesCount > 0 && (
                        <div className="notification">{club.MessagesCount}</div>
                      )}
                      {tab.id === "approval" && club.AwaitingCoachCount > 0 && (
                        <div className="notification">
                          {club.AwaitingCoachCount}
                        </div>
                      )}
                      {tab.label}
                    </div>
                  )
                );
              })}
            </div>
            <div className="wrapper-tabs">
              {activeTab === "profile" && (
                <div className="single-tab profile active">
                  <ProfileTab myProfile={myProfile} />
                </div>
              )}
              {activeTab === "maestri" && (
                <div className="single-tab players-section active">
                  <CoachesListTab club={club} user={Uid} />
                </div>
              )}
              {activeTab === "alievi" && (
                <div className="single-tab players-section active">
                  <PlayersTab club={club} role={role} />
                </div>
              )}
              {activeTab === "comunicazioni" && (
                <div className="single-tab comunications-section active">
                  <ComunicationsTab club={club} />
                </div>
              )}
              {activeTab === "test" && (
                <div className="single-tab test-section active">
                  <TestTab club={club} role={role} />
                </div>
              )}
              {activeTab === "subscription" && (
                <div className="single-tab subscription-section active">
                  <SubscriptionsTab club={club} />
                </div>
              )}
              {activeTab === "approval" && (
                <div className="single-tab approval-section active">
                  <CoachApprovalTest club={club} />
                </div>
              )}
              {activeTab === "suggestions" && (
                <div className="single-tab approval-section active">
                  <SuggestionsTab setActiveTab={setActiveTab} />
                </div>
              )}
            </div>
          </div>
          <Footer logoDev={logoDev} logoFooter={logoFooter} />
        </div>
      )}
    </>
  );
}
