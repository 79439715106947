import logoPolito from "../assets/partners/PoliTO.svg";
import logoFip from "../assets/partners/LogoFitp.png";
import logoI3p from "../assets/partners/i3p.svg";
import logoSportingTO from "../assets/partners/logo-circolo-sporting-torino-.png";
import logoAxis from "../assets/partners/Axis.png";
import howToWhite from "../assets/how-to.png";
import howToWhiteMobile from "../assets/how-to-mobile.png";
import howToWhiteEng from "../assets/how-to-eng.png";
import howToWhiteMobileEng from "../assets/how-to-mobile-eng.png";
import headerVideo from "../assets/Hypertennis-trailer.mp4";
import videoComplete from "../assets/Hypertennis-completo.mp4";
import headerVideoEng from "../assets/Hypertennis-trailer-eng.mp4";
import videoCompleteEng from "../assets/Hypertennis-completo-eng.mp4";
export const partners = [
  {
    id: 0,
    name: "Politecnico di Torino",
    urlLink: "https://www.polito.it/",
    img: logoPolito,
  },
  // {
  //   id: 1,
  //   name: 'Fip - Federazione Italiana Tennis',
  //   urlLink: 'https://www.fitp.it/',
  //   img: logoFip
  // },
  {
    id: 2,
    name: "I3P - Incubatore Imprese Innovative Politecnico di Torino",
    urlLink: "https://www.i3p.it/",
    img: logoI3p,
  },
  {
    id: 3,
    name: "Sporting Club",
    urlLink: "http://www.sporting.to.it/",
    img: logoSportingTO,
  },
  {
    id: 4,
    name: "Axis",
    urlLink: "https://www.axis.com/it-it",
    img: logoAxis,
  },
];
export const content = {
  it: {
    headerVideo: headerVideo,
    videoComplete: videoComplete,
    buttonVideo: "Guarda il video",
    bornSection: {
      title: "da chi nasce",
      text: `<p>
      <strong>HyperTennis</strong> nasce per iniziativa di Andrea Candusso, Laureato in Scienze Motorie, Coach e Tecnico Nazionale FIT, incordatore ufficiale della Nazionale di 
      <strong>Davis Cup</strong> del Kazakistan e Racquet Expert di diversi giocatori <strong>ATP Top 100</strong> tra cui<strong>Jannik Sinner</strong>,
      <strong>Lorenzo Sonego</strong> e<strong>Albert Ramos Vinolas</strong>.
    </p>
    <p>
      Nel corso degli anni <strong>HyperTennis</strong> ha creato
      un team di professionisti che hanno permesso di sviluppare
      nuovi prodotti e tecnologie innovative in grado di valutare
      sia le prestazioni dell’atleta che dei suoi attrezzi, senza
      trascurare gli aspetti di prevenzione.
    </p>`,
    },
    firstQuote: `La racchetta, il giocatore, la pallina e il movimento:<br />tutto in una soluzione!`,
    digitalCoach: ` <p>
    <strong>HyperTennis</strong> coniuga approfondite conoscenze
    Fisiche, Meccaniche, Biomeccaniche, Antropometriche con:
  </p>
  <ul>
    <li>- Tecnica Tennistica</li>
    <li>- Data & Motion Capture</li>
    <li>- AI & Machine Learning</li>
  </ul>`,
    secondQuote: ` Elaboriamo dati ed indicazioni per le Scuole Tennis e per i Pro:<br />fondamentali per coach e giocatori, per il miglioramento della tecnica,<br />
    della performance ed in ottica di prevenzione.`,
    howitworks: {
      title: "Come funziona",
      img: howToWhite,
      imgMobile: howToWhiteMobile,
    },
    partnersTitle: "COLLABORAZIONI E PARTNER TECNICI",
  },
  en: {
    headerVideo: headerVideoEng,
    videoComplete: videoCompleteEng,
    buttonVideo: "Watch the video",
    bornSection: {
      title: "born from",
      text: `<p>
      <strong>HyperTennis</strong> was born through the initiative of Andrea Candusso, a graduate in Sports Sciences, a coach and a National FIT (Italian Tennis Federation) coach, the official stringer for the Kazakhstan <strong>Davis Cup</strong> team, and a Racquet Expert for several <strong>ATP Top 100</strong> players including <strong>Jannik Sinner</strong>, <strong>Lorenzo Sonego</strong>, and <strong>Albert Ramos Vinolas</strong>.
    </p>
    <p>
    Over the years, <strong>HyperTennis</strong> has assembled a team of professionals who have facilitated the development of new products and innovative technologies capable of evaluating both the athlete's performance and their equipment, without neglecting preventive aspects.
    </p>`,
    },
    firstQuote: `The racket, the ball, the player and movement.<br/>All in a single solution!!`,
    digitalCoach: ` <p>
    <strong>HyperTennis</strong> provides in-depth Physical, Mechanical, Biomechanical and Anthropometric knowledge including
  </p>
  <ul>
    <li>- Tennis Technique</li>
    <li>- Data & Motion Capture</li>
    <li>- AI & Machine Learning</li>
  </ul>`,
    secondQuote: `We process data and recommendations for training camps and pros, providing crucial support to both coaches and players to help improve technique and performance and prevent injury.`,
    howitworks: {
      title: "How it works",
      img: howToWhiteEng,
      imgMobile: howToWhiteMobileEng,
    },
    partnersTitle: "COLLABORATIONS & TECHNICAL PARTNERS",
  },
};
