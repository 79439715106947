import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../hooks/auth/useAppContext';
import { useStandardCall } from '../../../hooks/useStandardCall';
import SingleInput from '../../../components/SingleInput';
import { LocalLoading } from '../../../components/Loading';
import { toast } from 'react-toastify';

export function TechnicalProfile({ myProfile, role = 'PLAYER' }) {
  /* const { TechnicalProfile } = myProfile; */
  const [TechnicalProfile, setTechnicalProfile] = useState(
    myProfile.TechnicalProfile
  );
  const stdCall = useStandardCall();
  const appContext = useContext(AppContext);
  const Locale = appContext.locale;
  const lookup = TheApp.Lookup[Locale];
  const [loading, setLoading] = useState(false);
  const [statusForm, setStatusForm] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({});
  
  useEffect(() => {
    if (statusForm) {
      if (statusForm?.success) {
        console.log('success technical');
        const toasts = toast.success(statusForm?.message);
      }
      if (!statusForm?.success) {
        console.log('fail technical');
        const toasts = toast.error(statusForm?.message);
      }
    }

  }, [statusForm]);
  const [inputs, setInputs] = useState([
    {
      id: 'Height',
      label: 'Altezza in cm',
      type: 'number',
      isDisabled: role === 'COACH' || role === 'TECH_DIRECTOR' ? true : false,
      defaultValue: TechnicalProfile.Height,
      validationSchema: {
        required:
          role === 'COACH' || role === 'TECH_DIRECTOR'
            ? null
            : 'Campo obbligatorio',
        min: { value: 1, message: 'Altezza minima 100 cm' }
      },
      placeholder: 'Inserisci la tua altezza in cm',
      layout: 'col-lg-6',
      icon: 'fa fa-arrows-v'
    },
    {
      id: 'Weight',
      label: 'Peso in Kg',
      type: 'number',
      isDisabled: role === 'COACH' || role === 'TECH_DIRECTOR' ? true : false,
      defaultValue: TechnicalProfile.Weight,
      validationSchema: {
        required:
          role === 'COACH' || role === 'TECH_DIRECTOR'
            ? null
            : 'Campo obbligatorio'
      },
      placeholder: 'Inserisci il tuo peso in kg',
      layout: 'col-lg-6',
      icon: 'fa fa-balance-scale'
    },
    {
      id: 'fitp',
      label: 'N. Tessera FITP',
      type: 'text',
      isDisabled: role === 'COACH' || role === 'TECH_DIRECTOR' ? true : false,
      defaultValue: myProfile.FITP,
      validationSchema:{
        pattern: {
          value: /^[0-9]*$/i,
          message: "Numero tessera FITP non valido"
        }
      },
      placeholder: 'Inserisci il numero della tua tessera',
      icon: 'fa fa-id-card',
      layout: 'col-lg-6'
    },
    {
      id: 'FITPExpireDate',
      isDisabled: role === 'COACH' || role === 'TECH_DIRECTOR' ? true : false,
      label: 'Scadenza tessera FITP',
      type: 'date',
      defaultValue:  myProfile.FITPExpireDate,
      validationSchema: {
        pattern: {
          value: /^\d{4}-\d{2}-\d{2}$/,
          message: 'Formato data non valido dd/mm/yyyy'
        }
      },
      placeholder: 'Inserisci il numero della tua tessera',
      icon: 'fa fa-calendar',
      layout: 'col-lg-6'
    },
    {
      id: 'DominantHand',
      type: 'radioGroup',
      label: 'Mano dominante',
      layout: 'col-lg-6',

      defaultValue: TechnicalProfile.DominantHandLeft
        ? 'DominantHandLeft'
        : TechnicalProfile.DominantHandRight
        ? 'DominantHandRight'
        : null,
      inputs: [
        {
          id: 'DominantHandLeft',
          label: 'Sinistra',
          type: 'radio',
          group: 'DominantHand',
          defaultValue: TechnicalProfile.DominantHandLeft
        },
        {
          id: 'DominantHandRight',
          label: 'Destra',
          type: 'radio',
          group: 'DominantHand',
          defaultValue: TechnicalProfile.DominantHandRight
        }
      ]
    },
    {
      id: 'OneHandBackhand',
      type: 'checkbox',
      onChangeCallback: handleOneHandBackhandChanged,
      label: 'Rovescio a una mano',
      defaultValue: TechnicalProfile.OneHandBackhand,
      layout: 'col-lg-6'
      /* validationSchema: {
        required: 'Campo obbligatorio'
      } */
    },
    {
      id: 'Forehand',
      type: 'select',
      label: 'Diritto',
      placeholder: 'Seleziona il tuo diritto',
      defaultValue: TechnicalProfile.Forehand ?{
        value: TechnicalProfile.Forehand ,
        label: TechnicalProfile.Forehand
      } : "",
      // validationSchema: {
      //   required: 'Campo obbligatorio'
      // },
      options: {
        Items: lookup.LookupForehand
      },
      layout: 'col-lg-6'
    },
    {
      id: 'BackhandDominantHand',
      type: 'select',
      label: 'Rovescio mano dominante',
      placeholder: 'Seleziona il tuo rovescio',
      defaultValue: TechnicalProfile.BackhandDominantHand ? {
        value: TechnicalProfile.BackhandDominantHand,
        label: TechnicalProfile.BackhandDominantHand
      } : "",
      options: {
        Items: lookup.LookupBackhandDominantHand
      },
      layout: 'col-lg-6'
    },
    {
      id: 'BackhandSecondaryHand',
      type: 'select',
      isDisabled: TechnicalProfile.OneHandBackhand,
      label: 'Rovescio mano secondaria',
      placeholder: 'Seleziona il tuo rovescio',
      defaultValue: TechnicalProfile.BackhandSecondaryHand ? {
        value: TechnicalProfile.BackhandSecondaryHand,
        label: TechnicalProfile.BackhandSecondaryHand
      } : "",
      options: {
        Items: lookup.LookupBackhandSecondaryHand
      },
      layout: 'col-lg-6'
    },
    {
      id: 'Serve',
      type: 'select',
      label: 'Servizio',
      placeholder: 'Seleziona il tuo servizio',
      defaultValue: TechnicalProfile.Serve ? {
        value: TechnicalProfile.Serve,
        label: TechnicalProfile.Serve
      } : "",
      options: {
        Items: lookup.LookupServe
      },
      layout: 'col-lg-6'
    },
    {
      id: 'Volley',
      type: 'select',
      label: 'Volée',
      placeholder: 'Seleziona la tua volée',
      defaultValue: TechnicalProfile.Volley ? {
        value: TechnicalProfile.Volley,
        label: TechnicalProfile.Volley
      } : "",
      options: {
        Items: lookup.LookupVolley
      },
      layout: 'col-lg-6'
    },
    {
      id: 'Smash',
      type: 'select',
      label: 'Smash',
      placeholder: 'Seleziona il tuo smash',
      defaultValue: TechnicalProfile.Smash ? {
        value: TechnicalProfile.Smash,
        label: TechnicalProfile.Smash
      } : "",
      options: {
        Items: lookup.LookupSmash
      },
      layout: 'col-lg-6'
    },
    {
      id: 'Level',
      type: 'select',
      label: 'Livello',
      placeholder: 'Seleziona il tuo livello',
      defaultValue: TechnicalProfile.Level ?{
        value: TechnicalProfile.Level,
        label: TechnicalProfile.Level
      } : "",
      options: {
        Items: lookup.LookupLevel
      },
      layout: 'col-lg-6'
    },
    {
      id: 'Ranking',
      label: 'Ranking',
      type: 'select',
      placeholder: 'Seleziona il tuo ranking',
      defaultValue: TechnicalProfile.Ranking ? {
        value: TechnicalProfile.Ranking,
        label: TechnicalProfile.Ranking
      } : "",
      options: {
        Items: [
          { Value: '2.1', Label: '2.1' },
          { Value: '2.2', Label: '2.2' },
          { Value: '2.3', Label: '2.3' },
          { Value: '2.4', Label: '2.4' },
          { Value: '2.5', Label: '2.5' },
          { Value: '2.6', Label: '2.6' },
          { Value: '2.7', Label: '2.7' },
          { Value: '2.8', Label: '2.8' },
          { Value: '3.1', Label: '3.1' },
          { Value: '3.2', Label: '3.2' },
          { Value: '3.3', Label: '3.3' },
          { Value: '3.4', Label: '3.4' },
          { Value: '3.5', Label: '3.5' },
          { Value: '4.1', Label: '4.1' },
          { Value: '4.2', Label: '4.2' },
          { Value: '4.3', Label: '4.3' },
          { Value: '4.4', Label: '4.4' },
          { Value: '4.5', Label: '4.5' },
          { Value: '4.6', Label: '4.6' },
          { Value: '4NC', Label: '4NC' }
        ]
      },
      layout: 'col-lg-6'
    }
  ]);

  function handleOneHandBackhandChanged(e){
    let temp = [...inputs];

    let BackhandSecondaryHandIndex = temp.findIndex(x => x.id == "BackhandSecondaryHand");
    temp[BackhandSecondaryHandIndex].isDisabled = e;
    setInputs(() => temp);
  }

  async function updateTechnicalInfo(data) {
    const {
      Height,
      Weight,
      DominantHand,
      OneHandBackhand,
      Forehand,
      BackhandDominantHand,
      BackhandSecondaryHand,
      Serve,
      Volley,
      Smash,
      Level,
      Ranking
    } = data;
    const rq =
      role === 'COACH' || role === 'TECH_DIRECTOR'
        ? {
          Role: role,
            UidPlayer: myProfile.Uid,
            TechnicalProfile: {
              Height,
              Weight,
              DominantHand,
              OneHandBackhand,
              Forehand: Forehand.value,
              DominantHandLeft:
                DominantHand === 'DominantHandLeft' ? true : false,
              DominantHandRight:
                DominantHand === 'DominantHandRight' ? true : false,
              BackhandDominantHand: BackhandDominantHand.value,
              BackhandSecondaryHand: BackhandSecondaryHand.value,
              Serve: Serve.value,
              Volley: Volley.value,
              Smash: Smash.value,
              Level: Level.value,
              Ranking: Ranking.value,
              HeightUnit: 'cm',
              WeightUnit: 'kg'
            }
          }
        : {
          Role: role,
            TechnicalProfile: {
              Height,
              Weight,
              DominantHand,
              OneHandBackhand,
              Forehand: Forehand.value,
              DominantHandLeft:
                DominantHand === 'DominantHandLeft' ? true : false,
              DominantHandRight:
                DominantHand === 'DominantHandRight' ? true : false,
              BackhandDominantHand: BackhandDominantHand.value,
              BackhandSecondaryHand: BackhandSecondaryHand.value,
              Serve: Serve.value,
              Volley: Volley.value,
              Smash: Smash.value,
              Level: Level.value,
              Ranking: Ranking.value,
              HeightUnit: 'cm',
              WeightUnit: 'kg'
            }
          };
    /* console.log(rq); */
    appContext._setters._setLoading(true);
    let call = stdCall.post('app/updatetechnicalprofile', rq, (rs) => {
      if (rs.Success) {

        if (role !== 'COACH') {
          const sub = appContext.userSession.userData.myProfile.Subscriptions;
          const newProfile = {
            ...appContext.userSession.userData.myProfile,
            TechnicalProfile: rs.TechnicalProfile,
            Subscriptions: sub
          };
          appContext._setters._setProfile(newProfile);
        }
        setTechnicalProfile(rs.TechnicalProfile);
        setStatusForm({
          status: true,
          success: true,
          message: 'Profilo aggiornato correttamente'
        });
        appContext._setters._setLoading(false);
      } else {
        setStatusForm({
          status: true,
          success: false,
          message: "Errore durante l'aggiornamento del profilo"
        });
        appContext._setters._setLoading(false);
      }
    });
  }
  return (
    <div className="wrapper-form">
      {loading && <LocalLoading />}
      <form onSubmit={handleSubmit(updateTechnicalInfo)}>
        <div className="row">
          {inputs.map((input, index) => {
            // Hides FITP / FITP EXPIRE DATE for players because it is already visible in user.data
            if(role === "PLAYER" && (input.id == 'fitp'  || input.id == 'FITPExpireDate')) return "";
            return (
              <SingleInput
                key={input.id}
                errors={errors}
                register={register}
                control={control}
                setValue={setValue}
                {...input}
              />
            );
          })}
        </div>
        <input
          value={'Salva'}
          type="submit"
          className="btn primary"
        />
      </form>
    </div>
  );
}
