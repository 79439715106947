import { useNavigate } from 'react-router-dom';
import { useStandardCall } from '../hooks/useStandardCall';
import { AppContext } from '../hooks/auth/useAppContext';
import './nav.scss';
import logoutIcon from "../assets/logout.svg";
import { useContext } from 'react';

export const Logout = () => {
  const appContext = useContext(AppContext);
  const stdCall = useStandardCall();
  const navigate = useNavigate();


  async function doLogout() {
    let rq = {
      ForceClearPrincipal: true
    };
    appContext._setters._setLoading(true);
    let call = stdCall.post('auth/logout', rq, (rs) => {
      if(rs?.Success){
        navigate(`/${TheApp.Env.LocaleShort}`);
        appContext._setters._setLoading(false);
        appContext._setters._setUserSession(null);
      }
      else{
        appContext._setters._setLoading(false);
      }
    });
  }
  return (
    <div>
      <div style={{display:'flex', alignItems:"center"}}
        onClick={doLogout}>
        Logout
        <img className='logout-icon' src={logoutIcon}/>
      </div>
    </div>
  );
};
