import './auth.scss';
import { useContext, useEffect, useState } from 'react';
import { useStandardCall } from '../../hooks/useStandardCall';
import { useForm } from 'react-hook-form';
import SingleInput from '../../components/SingleInput';
import { Loading } from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../hooks/auth/useAppContext';

export default function ResetPwd() {
  const stdCall = useStandardCall();
  const [resource, setResourse] = useState(TheApp.Res);
  const queryString = window.location.search;
  const token = new URLSearchParams(queryString).get('page_token');
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm();
  const [resetOk, setResetOk] = useState({
    status: false,
    message: '',
    success: false
  });
  const locale = TheApp.Env.LocaleShort;
  useEffect(() => {
    setResourse(TheApp.Res[TheApp.Locale]);
  }, [resource]);
  function onSubmitRequestReset(data) {
    appContext._setters._setLoading(true);
    const rq = {
      ...data,
      client_action_url: `${window.location.origin}/${locale}/reset-password` //TODO: capire se puo` andare bene
    };
    let call = stdCall.post('auth/passwordreset', rq, (rs) => {
      if (rs.Success) {
        setResetOk({
          status: true,
          success: true,
          message: resource['ResetPwdRequest.SuccessMessage']
        });
        reset();
        appContext._setters._setLoading(false);
        console.log('Email sent');
      } else {
        appContext._setters._setLoading(false);
        setResetOk({
          success: false,
          status: true,
          message: rs.Message
        });
        console.log('Email not sent');
      }
    });
  }
  function confirmPwdHandler(data) {
    const { Password, ...restData } = data;
    const rq = {
      new_password: Password,
      page_token: token
    };
    appContext._setters._setLoading(true);
    let call = stdCall.post('auth/passwordresetconfirm', rq, (rs) => {
      if (rs.Success) {
        appContext._setters._setLoading(false);
        reset();
        setResetOk({
          status: true,
          success: true,
          message: resource['ResetPwdChange.SuccessMessage']
        });
        setTimeout(() => {
          navigate(`/${locale}/auth/login`);
        }, 3000);
      } else {
        setResetOk({
          success: false,
          status: true,
          message: rs.Message
        });
      }
    });
  }
  // campi per la richiesta di reset
  const inputs = [
    {
      id: 'UserName',
      label: `${resource['Form.Email']}`,
      type: 'email',
      validationSchema: {
        required: `${resource['Form.RequiredLabel']}`,
        pattern: {
          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          message: 'Email non valida'
        }
      },
      placeholder: `${resource['Form.EmailPlaceholder']}`,
      icon: 'fa fa-user',
      layout: 'col-lg-12 col-md-6'
    }
  ];
  // campi per il reset
  const changePwdInputs = [
    {
      id: 'Password',
      label: 'Password',
      type: 'password',
      defaultValue: '',
      validationSchema: {
        required: `${resource['Form.RequiredLabel']}`,
        minLength: {
          value: 8
        },
        pattern: {
          value:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!£$%&=<>|])([^\s]){8,20}$/,
          message:
            'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale'
        }
      },
      placeholder: `${resource['Form.RequiredLabel']}`,
      icon: 'fa fa-lock',
      layout: 'col-lg-12 col-md-6'
    },
    {
      id: 'ConfirmPassword',
      label: `${resource['Form.PwdConfirmPlaceholder']}`,
      type: 'password',
      toCompare: true,
      defaultValue: '',
      validationSchema: {
        required: `${resource['Form.RequiredLabel']}`,
        validate: {
          matchPreviousValue: (value) => {
            const { Password } = getValues();
            return Password === value || 'Le password non coincidono';
          }
        }
      },
      placeholder: 'Conferma la tua password',
      icon: 'fa fa-lock',
      layout: 'col-lg-12 col-md-6'
    }
  ];
  return (
    <>
      <div className="page reset-pwd">
        <div className="wrapper-auth login">
          <div className="wrapper-logo-form">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo-footer.png`}
              alt="Hyper Tennis"
            />
          </div>
          <div className="wrapper-form">
            <div className="wrapper-title">
              <h1>Reset password</h1>
            </div>
            {/* se esiste il token nella query string mostro il form per il cambio */}
            {queryString ? (
              <form onSubmit={handleSubmit(confirmPwdHandler)}>
                <div className="row">
                  {changePwdInputs.map((input) => (
                    <SingleInput
                      key={input.id}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      {...input}
                    />
                  ))}
                </div>
                <input
                  type="submit"
                  value={`Reset Password`}
                  className="btn primary"
                />
              </form>
            ) : (
              <form onSubmit={handleSubmit(onSubmitRequestReset)}>
                <div className="row">
                  {inputs.map((input) => (
                    <SingleInput
                      key={input.id}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      {...input}
                    />
                  ))}
                </div>
                <input
                  type="submit"
                  value={`Reset Password`}
                  className="btn primary"
                />
              </form>
            )}
            <div className="back-login">
              <a href={`/${locale}/auth/login`}>{resource['Login.SignUpGoBack']}</a>
            </div>
          </div>
          {resetOk.status && (
            <div
              className={`wrapper-message ${
                resetOk.success ? 'alert-success' : 'alert-danger'
              }`}>
              {resetOk.status && <p>{resetOk.message}</p>}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
