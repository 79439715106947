/* eslint-disable */
import { useContext } from 'react';
import { AppContext, useAppContext } from './auth/useAppContext';

export function useStandardCall() {
  const appContext = useContext(AppContext);

  function _handlerStandardCall(rqInfo, $rs, $error) {
    let rs = {
      StatusCode: 503,
      Message: 'ServiceUnavailable',
      Exception: null,
      Success: false
    };
    //console.log({ rqInfo, rs, error });
    if ($rs && $rs.StatusCode && !$error) {
      rs = $rs;
    }
    if (rqInfo.callback) {
      rqInfo.callback(rs);
    }
  }
  //
  function _callStandardCall(apiRoute, rq, callback, method = 'POST') {
    const rqInfo = {
      apiRoute,
      rq,
      callback
    };
    // query string
    let qs = '';
    if (method === 'GET' && rq) {
      qs =
        (apiRoute.indexOf('?') === -1 ? '?' : '&') +
        new URLSearchParams(rq).toString();
    }
    //console.log(`CALL ${method} ${apiRoute} ${JSON.stringify(rq)}`);
    fetch(`${process.env.REACT_APP_REST_URL}${apiRoute}${qs}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        api_key: TheApp.CurrentContext.app.jsApiKey,
        access_token: appContext.userSession
          ? appContext.userSession.Bearer
          : null,
        lng: appContext.locale
      },
      method: method,
      credentials: 'include', // IMPORTANTE
      body: method !== 'GET' && rq ? JSON.stringify(rq) : null
    })
      .then((response) => response.json())
      .then((data) => {
        _handlerStandardCall(rqInfo, data, null);
      })
      .catch((error) => {
        _handlerStandardCall(rqInfo, null, error);
      });
  }

  return {
    post: (apiRoute, rq, callback) =>
      _callStandardCall(apiRoute, rq, callback, 'POST'),
    get: (apiRoute, rq, callback) =>
      _callStandardCall(apiRoute, rq, callback, 'GET'),
    del: (apiRoute, rq, callback) =>
      _callStandardCall(apiRoute, rq, callback, 'DELETE')
  };
}
