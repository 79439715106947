import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip
} from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import circleInfo from '../assets/icon/circle-info-solid.svg';
const SingleInput = ({
  id,
  label,
  type,
  validationSchema,
  register,
  errors,
  control,
  setValue,
  ...inputProps
}) => {
  let selectOptions = type === 'select' ? inputProps.options : null;
  if (selectOptions) {
    selectOptions = selectOptions?.Items?.map((item) => ({
      value: item.Value,
      label: item.Label
    }));
  }
  useEffect(() => {
    setValue(id, inputProps.defaultValue);
  }, [inputProps.defaultValue, id, setValue]);
  const [showPwd, setShowPwd] = useState(false);
  return (
    <div
      className={`wrapper-input ${inputProps.layout || 'col-lg-12 col-md-6'}`}
      key={id}>
      {type === 'textarea' && (
        <div className={`single-input input-${type}`}>
          <TextField
            variant="outlined"
            size="medium"
            fullWidth
            color={'primary'}
            focused
            multiline
            label={label}
            rows={10}
            {...register(id, validationSchema)}
          />
        </div>
      )}
      {type === 'select' ? (
        <div className={`single-input input-${type}`}>
          <label
            htmlFor={id}
            style={{ marginBottom: '.5rem', display: 'block' }}>
            {label}
          </label>
          <Controller
            defaultValue={''}
            name={id}
            control={control}
            render={({ field }) => {
              return (
                <Select
                  className="reactSelect"
                  name={id}
                  defaultValue={''}
                  isMulti={inputProps.isMulti}
                  placeholder={inputProps.placeholder}
                  options={selectOptions}
                  isDisabled={inputProps.isDisabled}
                  menuPosition="fixed"
                  menuPlacement="auto"
                  {...register(id, validationSchema)}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderWidth: 2,
                      borderColor: errors[id] ? '#d32f2f' : '#1976D2',
                      padding: '0.5rem',
                      textTransform: 'capitalize',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 100,
                    })
                  }}
                  {...field}
                />
              );
            }}
          />

          {/* {errors[id] && <p className="error-message">{errors[id].message}</p>} */}
        </div>
      ) : (
        <div className={`single-input input-${type}`}>
          {/* <label htmlFor={id}>{label}</label> */}
          {/* {inputProps.icon && <i className={inputProps.icon}></i>} */}
          {type === 'checkbox' && (
            <Controller
              name={id}
              defaultValue={inputProps.defaultValue}
              control={control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value ? true : false}
                        {...register(id, validationSchema)}
                        {...field}
                        onChange={(e) => {
                          if(inputProps?.onChangeCallback){
                            inputProps.onChangeCallback(e.target.checked);
                            field.onChange(e.target.checked);
                          }
                          else{
                            field.onChange(e.target.checked);
                          }
                        }}
                        sx={{
                          color: errors[id] ? '#d32f2f' : '#00DAFF',
                          '&.Mui-checked': {
                            color: '#00DAFF'
                          }
                        }}
                      />
                    }
                    label={label}
                  />
                );
              }}
            />
          )}
          {type === 'radioGroup' && (
            <div className={`single-input input-${type}`}>
              <label htmlFor={id}>{label}</label>
              <Controller
                name={id}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <RadioGroup
                  sx={{
                    marginLeft:'7px'
                  }}
                    aria-label={id}
                    row
                    defaultValue={inputProps.defaultValue}
                    {...field}>
                    {inputProps.inputs.map((input) => (
                      <FormControlLabel
                        key={input.id}
                        value={input.id}
                        control={
                          <Radio
                            sx={{
                              color: '#00DAFF',
                              '&.Mui-checked': {
                                color: '#00DAFF'
                              }
                            }}
                          />
                        }
                        label={input.label}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </div>
          )}
          {/* da valutare se usare muiu anche per i textfield questo è il codice originale	
           <input
              type={type === 'password' && showPwd ? 'text' : type}
              placeholder={inputProps.placeholder}
              {...register(id, validationSchema)}
            /> */}
          {type !== 'radioGroup' &&
            type !== 'checkbox' &&
            type !== 'textarea' && (
              <>
              <TextField
              // helperText={errors[id]?.message}
                type={type === 'password' && showPwd ? 'text' : type}
                placeholder={inputProps.placeholder}
                {...register(id, validationSchema)}
                disabled={inputProps.isDisabled}
                variant="outlined"
                size="medium"
                fullWidth
                step="any"
                color={'primary'}
                focused
                required={inputProps.isRequired ? true : false}
                label={label}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {inputProps.icon && <i className={inputProps.icon}></i>}
                    </InputAdornment>
                  ),
                  
                  endAdornment: (
                    inputProps?.tooltip?.message && (
                    <InputAdornment position='end'>
                      <Tooltip placement='bottom'  title={<h1 style={{ fontSize: "15px" }}>{inputProps?.tooltip?.message}</h1>}>
                        <IconButton sx={{
                          width:"35px",
                        }}>
                          <img style={{width:"35px"}} src={circleInfo}/>
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                    )
                  )
                }}
              />
              </>
            )}
          {type === 'password' && (
            <div
              className="toggle-pwd"
              onClick={() => setShowPwd(!showPwd)}>
              <i
                className={`fa fa-eye${showPwd ? '-slash' : ''}`}
                aria-hidden="true"></i>
            </div>
          )}
        </div>
      )}
      {errors[id] && <p className="error-message">{errors[id].message}</p>}
    </div>
  );
};
export default SingleInput;
