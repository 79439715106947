import { useContext } from "react";
import { AppContext } from "./auth/useAppContext";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
export const useStandardFetch = (apiRout, method, rq) => {
  const appContext = useContext(AppContext);
  let qs = "";
  if (method === "GET" && rq) {
    qs =
      (apiRout.indexOf("?") === -1 ? "?" : "&") +
      new URLSearchParams(rq).toString();
  }
  const fetcher = (url) =>
    fetch(url, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        api_key: TheApp.CurrentContext.app.jsApiKey,
        access_token: AppContext.userSession
          ? appContext.userSession.Bearer
          : null,
        lng: appContext.locale,
      },
      method: method,
      body: method !== "GET" ? JSON.stringify(rq) : null,
    }).then((res) => res.json());
  const [data, error] = useSwr(
    `${process.env.REACT_APP_REST_URL}${apiRout}${qs}`,
    fetcher
  );
  return [data, { error, loading: !data && !error }];
};

export const useSWRFetcher = (url, shouldFetch = true) => {
  const appContext = useContext(AppContext);
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    access_token: appContext.userSession ? appContext.userSession.Bearer : null,
    api_key: TheApp.CurrentContext.app.jsApiKey,
  };
  const fetcher = (url) =>
    fetch(url, {
      headers,
      credentials: "include",
    }).then((res) => res.json());
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? `${process.env.REACT_APP_REST_URL}${url}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return { data, error, isLoading, mutate };
};

export const useSWRInfiniteFetcher = (url, pageSize) => {
  const appContext = useContext(AppContext);
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    access_token: appContext.userSession ? appContext.userSession.Bearer : null,
    api_key: TheApp.CurrentContext.app.jsApiKey,
  };
  const fetcher = (url) =>
    fetch(url, {
      headers,
      credentials: "include",
    }).then((res) => res.json());
  let { data, mutate, size, setSize, isValidating, isLoading } = useSWRInfinite(
    (index) =>
      `${process.env.REACT_APP_REST_URL}${url}&pageIndex=${index}&pageSize=${pageSize}`,
    fetcher
  );
  return { data, mutate, size, setSize, isValidating, isLoading };
};
