import { motion } from 'framer-motion';
import court from '../../../assets/tennis_court.jpg';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
export function ItemTestList({ test, ClubName, handlerSetDetailTest }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  const img = test.UrlImageKeyFrame ? `${TheApp.Env.BaseUrl}${test.UrlImageKeyFrame}` : court;

  useEffect(() => {
    if(searchParams.get("test")){
      if(test.Uid === searchParams.get("test"))
        handlerSetDetailTest(test);
    }
  }, [searchParams])
  return (
    <motion.div
      variants={item}
      className="single-test row xs-p-0 xs-m-0"
      key={test.Uid}>
      <div className="col-lg-5 xs-p-0">
        <div className="wrapper-img">
          <img
            src={`${img}`}
            alt=""
          />
        </div>
      </div>
      <div className="col-lg-7 xs-p-0">
        <div className="wrapper-info">
          <h2>
            {test.TestName} {test.TestDurationMin} minuti
            <span>
              <span className="label">
                <i className="fa-regular fa-calendar-check"></i> Data:{' '}
              </span>
              {test.Date}
            </span>
          </h2>
          <h4><i className="fa-solid fa-location-dot"></i> {ClubName}</h4>
        </div>
        <article className="card-notes">
          <h3><i className="fa-regular fa-note-sticky"></i> Note {' '}</h3>
          <p>{test.CoachNote}</p>
        </article>
        {/* 
        <ul>
          <li>
            <span className="label">
              <i className="fa-regular fa-calendar-check"></i>{' '}
              Data:{' '}
            </span>
            {test.Date}
          </li> 
          <li>
            <span className="label">
              <i className="fa-regular fa-star"></i> Overall Rating:{' '}
            </span>
            {test.OverallRating}
          </li> 
        </ul>
        */}
        <div
          className="btn secondary"
          onClick={() => {
            setSearchParams({test: test.Uid});
            handlerSetDetailTest(test);
          }}>
          visualizza test
        </div>
      </div>
    </motion.div>
  );
}
